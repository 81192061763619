import React from 'react';
import {Link} from 'react-router-dom'
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '350',
    width: '100%',
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      mute: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
  
  const Introduction = () => {
   
  const videoId = '71NGqxCw4Po';
  return (
    <>
      <div className="container mt-5 intro_container">
        <div className="row align-items-center">
            <div className="col-md-6">
              <div className="video_player-container">
                <VideoPlayer videoId={videoId}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content__title">
                <h2>
                  <span>About Us</span>
                </h2>
                <p>
                  VALOORES empowers decision making, helping people and businesses around the globe reach their targets. Founded in 1989 in France, the Company is a pioneer in Master Data Governance, Retail & Merchandising, Supply Chain Optimization, KYC, Regulatory Compliance, Financial Crime - AML & Fraud, Predictive Analytics and Data Science to improve their on-going operations, executions and decisions.
                </p>
                <div className="button_div">
                    <Link className="btn btn-show-sec" to='/aboutUs?variable=Contact' target= '_blank'>Read More</Link>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Introduction