import React, {useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade'
import Banner from './CareerBanner'
import Intro from './Introduction'
import JobsCat from './JobsCat'
import AllJobs from './Jobs'
import Hr1 from '../../img/hr/hr1.png'
import Hr2 from '../../img/hr/hr2.png'
import Hr3 from '../../img/hr/hr3.png'
import Hr4 from '../../img/hr/hr4.png'
import T2R from '../../img/hr/t2r.png'
import Values from '../../img/hr/values.jpg'
import HrBanner from '../../img/banners/recruitment.jpg'
import Hrr1 from '../../ValooresNew/assets/img/hr1.jpg'
import Hrr2 from '../../ValooresNew/assets/img/hr2.jpg'
import Hrr3 from '../../ValooresNew/assets/img/hr3.jpg'
import Hrr4 from '../../ValooresNew/assets/img/hr4.jpg'
import recruitimg from '../../ValooresNew/assets/img/recruitment11.jpg'
import Job1 from '../../img/hr/1.jpg'
import Job2 from '../../img/hr/2.jpg'
import Job3 from '../../img/hr/3.jpg'
import Job4 from '../../img/hr/4.jpg'
import Job5 from '../../img/hr/5.jpg'
import Job6 from '../../img/hr/6.jpg'
import Job7 from '../../img/hr/7.jpg'
import Job8 from '../../img/hr/8.jpg'
import Job9 from '../../img/hr/9.jpg'
import './Career.css'


const Career = () => {

  const [searchAll, isSearchAll] = useState(null)
  
  const process = [
    {
      img: Hr1,
      title: '1. Entrance Test',
      desc: 'We start our recruitment process with an entrance test to determine whether you have the personal skills required for the role. This entrance test includes psychometric test, personality test, comprehension, IQ and General Knowledge.',
    },
    {
      img: Hr2,
      title: '2. HR Interview',
      desc: 'After reviewing the Entrance tests, our Human Resources Department selects the candidates that fit the most to the role and invite them for a first interview. This interview is to determine the personal and professional skills of the candidate and to inform him/her about VALOORES.',
    },
    {
      img: Hr3,
      title: '3. Technical & Business Interview',
      desc: 'The short listing process involves a detailed review of experience and skills against open positions. Short listed candidates, will be invited for an interview with the Head of Department. The purpose of this interview is to measure the technical and business knowledge required by the position.',
    },
    {
      img: Hr4,
      title: '4. Job Offer',
      desc: 'A job offer will be extended to the successful candidate that not only has the skills and abilities for the job, but to the one that demonstrates a desire and willingness to work in a professional and friendly environment.',
    },
  ]

  const [activeJob, setActiveJob] = useState(0)
  const [activeJobCat, setActiveJobCat] = useState('Engineering And Design')

  const handleActive = (index, catJob) =>{
      setActiveJob(index);
      setActiveJobCat(catJob);
  }


  const categories =
  [
      {
          job: 'Engineering And Design',
          img:Job1,
          link: 'Engineer',
      },
      {
          job: 'Business Development',
          img:Job2,
          link: 'Business',
      },
      {
          job: 'Professional Services And Consulting',
          img:Job3,
          link: 'Consulting',
      },
      {
          job: 'Product Management',
          img:Job4,
          link: 'Product',
      },
      {
          job: 'Solution Engineering',
          img:Job5,
          link: 'Solution',
      },
      {
          job: 'Marketing',
          img:Job6,
          link: 'Marketing',
      },
      {
          job: 'Education Services And Technical Support',
          img:Job7,
          link: 'Education',
      },
      {
          job: 'Information Technology',
          img:Job8,
          link: 'IT',
      },
      {
          job: 'Finance And Accounting',
          img:Job9,
          link: 'Finance',
      },
  ]

  
  const handleSearchAll = () => {
    isSearchAll('all')
  };

  const targetSectionRef = useRef(null);

    // Function to handle scrolling to the target section
    const scrollToTargetSection = () => {
      if (targetSectionRef.current) {
        targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    

  return (
    <>
        {/* <Banner 
            img= {Hrr3}
            top='35%'
        /> */}
        <div>
        <div class='career_banner' style={{backgroundImage: `url('${recruitimg}')`, backgroundRepeat:'no-repeat', backgroundSize:'cover'}}></div>
        <div className="overlay_bg-career"></div>
        <div className='career_content' style={{top : '10%'}}>
        <Fade left>
          <div
           className='hr_content_1'
          >
            <h1 className='hr'>
              Are you looking
            </h1>
          </div>

          <div
            className='hr_content_2'
          >
            <h1 className='hr'>
              to build your career?
            </h1>
          </div>

          <div
            className='hr_content_3'
          >
            <h1 className='hr'>
              Join us, and dare to imagine!
            </h1>
          </div>
        </Fade>



        </div>
    </div>
        <div className="container">
          <div className='my-5'>
            <Intro />

          </div>
        </div>
        <div className='mt-5 shadow_custom pt-5'>
          <JobsCat
            categories = {categories}
            handleActive = {handleActive}
            activeJob= {activeJob}
            activeJobCat={activeJobCat}
            scrollToTargetSection = {scrollToTargetSection}
            handleSearchAll={handleSearchAll}
            />
        </div>
        {/* <hr className='my-5' style={{opacity: '0.5', height: '0.2rem', backgroundColor: '#000'}}/> */}
        <div className='py-5 shadow_custom' style={{background: '#f1f1f1'}}>
          <AllJobs activeJobCat={activeJobCat} targetSectionRef = {targetSectionRef} searchAll = {searchAll}/> 
        </div>
        <div className="shadow_custom">
          <div className="process container pt-5">
            <div className="process_header text-center">
              <h3 style={{color: '#007ac1', fontWeight: 'bold'}}>The Process</h3>
              <p>Our Recruitment And Selection Process</p>
              <p>VALOORES' objective is to recruit the best suited candidate to the vacant position. Our Human Resources Department invest a lot of time and effort into the recruitment and selection process in order to recruit the best! Our recruitment and selection process is not based only on academic achievements or working experience; we use a combination of assessment tests and interviews to select the best candidate for the position. During this process, you will have the opportunity to show us how you can add value to the company and how you are a great fit for our company. Depending on the requirements of every position, there may also be other requests. Applications will remain in our database and will be considered for any future vacant position if the applicant's experience and the post qualifications are deemed a fit.</p>
            </div>  
            <div className="process_body mt-5 d-flex flex-column align-items-center">
              <span className='mb-3' style={{color: '#007ac1'}}>"We work hard, appreciate our people, train them well, promote on merit and provide international opportunities."</span>
              <div className="row my-3">
                {process.map((pro, index) =>(
                  <div className="col-md-3 text-center d-flex flex-column gap-3 align-items-center" key={index}>
                    <Fade bottom>
                      <img src={pro.img} className='mb-3' alt="process" style={{width:'85px'}}/>
                    </Fade>
                    <h5 style={{fontSize: '24px', color: '#007ac1'}}>{pro.title}</h5>
                    <p style={{fontSize: '16px'}}>{pro.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='shadow_custom' style={{background: '#f1f1f1'}}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <h4 style={{color: '#007ac1'}}>Our Working Environment</h4>
                  <p>As a company, we favor ability over experience. We take pride in our employees' creativity and their drive to innovate. Therefore, we encourage them to ask questions, to think differently and challenge themselves. And most importantly, we make sure to provide a supportive environment where teamwork is encouraged.</p>
                </div>
                <div>
                  <h4 style={{color: '#007ac1'}}>Development of talents</h4>
                  <p>
                  We consider the opportunity to grow and develop skills one of the most important factors in motivating our employees. To show them how much we appreciate their efforts, we do everything we can to make sure they feel included, supported and encouraged no matter what position they are in.
                    <br/><br/>
                    Our workforce is diverse with a variety of development and training needs. Our employees are expected to be experts in their field, and we promise this level of professional expertise to our clients. With that in mind, we make sure to provide effective training for all our employees.
                  </p>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <img src={T2R} alt="t2r" />
              </div>
            </div>
          </div>
        </div>
        <div className="shadow_custom">
          <div className="container pt-5">
            <div className="row d-flex flex-row align-items-center">
              <div className="col-md-6">
                <img src={Values} alt="values" />
              </div>
              <div className="col-md-6">
                <ul className='values_list' style={{lineHeight: '2.5rem'}}>
                  <h5 style={{color: '#007ac1'}}>Our Values</h5>
                  <li>We believe in what we do</li>
                  <li>We listen, we care, we act.</li>
                  <li>We do what we say we'll do.</li>
                  <li>We innovate and constantly improve.</li>
                  <li>We trust our people and their potential.</li>
                  <li>We take care of our clients as if they are us.</li>
                  <li>We practice transparent and open communication.</li>
                  <li>We provide great services that differentiate us & set us apart.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Career