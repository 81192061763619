import React, { useEffect } from 'react';

const GoogleSearch = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cse.google.com/cse.js?cx=57d63d2fd10bd4059";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
      <div className="search-wrapper">
      <div className="gcse-search"></div>
    </div>
  );
};

export default GoogleSearch;