import React, { useState, useEffect} from 'react';
import Carousel from './AML/Carousel';
import Retail from './AML/Retail';

const Aml = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const currentURL = window.location.href;
  // const urlSegments = currentURL.split('/');
  // const lastSegment = urlSegments[urlSegments.length - 1];
  // console.log(lastSegment);
  let content;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [activeIndex]);

  switch (activeIndex) {
    case 0:
      content = <Retail />;
      break;
    // case 1:
    //   content = <Retail />;
    //   content = <Healthcare />;
    //   break;
    // case 2:
    //   content = <Retail />;
    //   content = <Financial />;
    //   break;
    // case 3:
    //   content = <Retail />;
    //   content = <Law />;
    //   break;
    default:
      content = <Retail />;
      break;
  }

  return (
    <>
      <Carousel setActiveIndex={setActiveIndex} />
      {content}
    </>
  );
};

export default Aml;
