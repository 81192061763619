import React from 'react'
import MissionBg from '../img/Mission.jpg'
import Sidebar from '../Pages/Banking/BankingPages/SideMenu.jsx'
// import MissionBg1 from '../img/Mission2.jpg'

const Mission = () => {
  console.log('selected');
  const currentURL = window.location.href; 
  return (
    <>
      <div className="row mission_container p-3 d-flex">
        <div className="col-md-10">
          <div className="mission_bg">
            <div className="vision_text">
              <h4>It's Valoores</h4>
              <p className='spacing_out'>VALOORES, as a privately held company, has embraced the metamorphosing Banking & Finance business models, to sustain growth, and tackle challenges segregated around governance, risk, compliance, profitability, payments, competition, branding, loyalty, Omni-channel experience, trust and engagement and commoditization.
              <br/>VALOORES has introduced an ROI-aware, decision-based mindset around its line of solutions to address lost opportunities and cope with the industry trends, models, and growth.
              <br/>VALOORES’ solutions are conceived to co-exist with the Banks’ already existing ecosystem, shielded from the core banking and the already existing Front / Back Office systems, to effectively address multi-directional financials, and secure strategies & objectives.
              <br/>
              <br/>VALOORES’ products road map is one of the most flexible in the industry. We constantly absorb new market demands and shrink their time to market. Our added value is the simplicity and elegance of our approach. VALOORES recognizes that every bank is different, and there is no “One Solution that fits all” mind-set, so we provide a large number of configurable options at every level of our solutions in order to meet set expectations and when needed, adapt, tailor, or change any part of our solution to your needs - all while adhering to our strict design standards and customization rules.
              <br/>
              <br/>The VALOORES Data Governance cushion accommodates future diversification through Lines of Business, without having to revisit the architecture blueprint: secure information integrity, streamline consistent consolidation, and drive tracking (centralize customer file against black listing & KYC ...).
              <br/>
              <br/>VALOORES’ solution set leverages Financial Analytics’ (FinAL) Automation on heavy manual compilation of Balance Sheet, Income Statements, Cash Flow, Ratios, and Financial KPIs / KRIs, alongside a GRC rule-based engine: Financial backbone needs to be automated, with data cleansing in the background, to stretch the analysis bandwidth while gauging and vetting out profitability (switching gears from 80% preparation and 20 % analysis, /to/, 20% preparation and 80% on profitability analysis and strategy). VALOORES tackles the Investment Portfolio automation to rule out manual efforts in consolidation, checks and balances, and industrialize analysis / interpretation.</p>
            </div>
            <div className="vision_text">
              <h4>Vision</h4>
              <p className='spacing_out'>Valoores' vision is to help people and organizations make the best decisions that will positively impact overall growth and performance.</p>
            </div>
            <div className="mission_text mt-5">
              <h4>Mission</h4>
              <p className='spacing_out'>Our mission is to provide a comprehensive and intuitive solution based on cutting-edge technology, tailored to meet the unique needs of businesses. Our solutions offer interactive tools, allowing users to optimize their operational results, reduce administrative costs, and maintain regulatory compliance and international standards. Our commitment is to deliver a flexible and customizable solution that empowers organizations to achieve your goals efficiently and effectively.</p>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          {/* <div className='mission_img' style={{backgroundImage: `url(${MissionBg1})`}}></div> */}
          <Sidebar url={currentURL}/>
        </div>
      </div>
    </>
  )
}

export default Mission