const details = [
    {
    title: 'Software Architect',
    requirements: [
      { id: 1, detail: "Proficient in Python, Java, Angular framework, SQL, and PostgreSQL." },
      { id: 2, detail: "Experience in designing and integrating APIs." },
      { id: 3, detail: "Experience with CI/CD pipelines, automation, cloud environments, containerization, and scalable applications." },
      { id: 4, detail: "Strong understanding of software architecture, design patterns, and software development best practices." },
      { id: 5, detail: "Proven experience leading projects, guiding junior developers, and working in dynamic teams." },
    ],
    responsibilities: [
        { id: 1, detail: "Design and implement software architecture that meets business and technical requirements." },
        { id: 2, detail: "Define technical standards, best practices, and architectural guidelines." },
        { id: 3, detail: "Collaborate with stakeholders, developers, and product managers to translate business needs into technical solutions." },
        { id: 4, detail: "Lead the selection of appropriate technologies, frameworks, and tools." },
        { id: 5, detail: "Ensure software solutions are scalable, maintainable, and secure." },
        { id: 6, detail: "Conduct code reviews and provide technical mentorship to development teams." },
        { id: 7, detail: "Identify and resolve architectural and performance issues." },
        { id: 8, detail: "Stay updated with emerging technologies and industry trends." },
      ],
      qualifications: [
        { id: 1, detail: "Degree in Computer Science or related field." },
        { id: 2, detail: "6+ years of demonstrable experience in software architecture." },
        { id: 3, detail: "Proficiency in software methodologies and frameworks." },
        { id: 4, detail: "Experience with user interface design, database structures, and an analytical mindset." },
        { id: 5, detail: "Good problem-solving skills and good organizational skills." },
        { id: 6, detail: "Patience and perseverance to overcome challenges, solve problems, and learn new things." },
        { id: 7, detail: "Advanced knowledge in Java, Python, and SQL." },
        { id: 8, detail: "Ability to work under tight deadlines." },
        { id: 9, detail: "Excellent written and verbal communication." },
        { id: 10, detail: "Ability to lead a team." },
      ]
},
];

export default details;