import React from 'react'
import Sidebar from '../../Pages/Banking/BankingPages/SideMenu.jsx'
import Banner from '../../Pages/inData/Banner';
import Content from '../../Pages/inData/Content';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import {useLocation,Link} from 'react-router-dom';
import InV21 from '../../Pages/inData/content/InV21.jsx';
import pdf1 from '../../ValooresNew/assets/pdf/VBS-V20-Release Note-v0.1.pdf';
import BannerImg from '../../ValooresNew/assets/img/industriesBanner.jpg'

const Feature = () => {
    const location = useLocation();
    const currentURL = window.location.href;
    // const searchParams = new URLSearchParams(location.search);
    // const receivedVariable = searchParams.get('variable');
    // const receivedVariable = searchParams.get('variable');
    const receivedVariable = 'InV21';
    let title='VALOORES announces the Next Generation v20 Ecosystem';
    // let BannerImg,BannerDesc, ;
    let RiskArray=InV21;
    // let overview, demo, pdf, fact, paper;
    let demo = 'no';
    let pdf = 'no';
    let fact = 'no';
    let paper = 'no';   
    const BannerDesc = (
        <>
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            “VALOORES Next Generation v20 Ecosystem”
          </span>
          <br/>
          <span style={{ fontSize: '16px',display: 'flex', lineHeight: '1.8' }}>
            VALOORES announces the Next Generation v20 ecosystem that marches at the same drum with the latest Digital Transformation guidelines, to align with the metamorphosing Business models, benchmark Data Readiness, and explore Data Challenges when addressing Complex and Changing Business requirements.
          </span>
          <p>
            <Link to={pdf1} target="_blank" rel="noopener noreferrer" style={{ width:'200px',height:'60px',backgroundColor:'#051c2c',borderRadius:'30px',fontSize: '15px', color:'#fff',textDecoration:'none',padding:'20px', lineHeight: '5'}}>View more details</Link>
          </p>
        </>
      );
        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container my-4'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    {/* <Content RiskArray = {RiskArray}/> */}
                    {RiskArray.map((item, index) => (
                <>
                <Fade>
                    <div className='py-2'>
                    <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                    <h7 style={{textTransform: 'uppercase',color:'black',fontWeight:'600'}}>{item.subtitle}</h7>
                    <br/>
                    {item.desc}
                        {item.list != '' && 
                        item.list.map((itemList, idx) => (
                            <p key={idx}>{itemList}</p>
                        ))
                        }
                        
                    </div>
                </Fade>
                </>
                ))}
                <div className='my-5'>
                    <Link to={pdf1} target="_blank" rel="noopener noreferrer" style={{ width:'200px',height:'60px',backgroundColor:'#051c2c',borderRadius:'30px',fontSize: '15px', color:'#fff',textDecoration:'none',padding:'20px'}}>View more details</Link>
                </div>
                 </div>
                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar currentURL={currentURL}/> 
                </div>
            </div>
        </>
    )
}

export default Feature;