import React from 'react'
import Sidebar from '../Banking/BankingPages/SideMenu'
import Banner from './Banner'
import Content from './Content'
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom';
import inMarketing1 from '../../img/inmarketing/inMarketing1.jpg';
import inMarketing2 from '../../img/inmarketing/inMarketing2.jpg';
import inMarketing3 from '../../img/inmarketing/inMarketing3.jpg';
import inkYEArray from './content/InDeals'
import inkYcArray from './content/InKYC'
import inSupplierManagement from './content/InSupplierManagement'
import inContactArray from './content/InContact'
import inFoundationArray from './content/InFoundation'
import inSourcingArray from './content/InSourcing'
import inProductManagerArray from './content/InProductManager'
import inOrganizationArray from './content/InOrganization'
import inProductManagementArray from './content/InProductManagement'
import inChanelArray from './content/InChanel'
import InCampaignManagement from './content/InCampaignManagement';
import InPricingManagement from './content/InPricingManagemeny';
import InDeals from './content/InDeals';

const Feature = () => {
    const location = useLocation();
    const currentURL = window.location.href;
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('variable');
    console.log(receivedVariable);
    let title, BannerImg, BannerDesc, RiskArray, overview, demo, pdf, fact, paper;    

    switch(receivedVariable){
        case 'InCampaignManagement':
          
            title = "in'Campaign Management"
            BannerImg = inMarketing1
            BannerDesc = "“In’Campaign ensures that each and every marketing campaign is well organized, built and launched in order to achieve the best result possible for your products and services.”"
            RiskArray = InCampaignManagement
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InPricingManagement':
           
            title = "in'Pricing Management"
            BannerImg = inMarketing2
            BannerDesc = "“Choosing the right price, for a product or service, can make all the difference to companies’ operations. in’Pricing offers calculation techniques which delivers effective results.”"
            RiskArray = InPricingManagement
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InDeals':
           
            title = "in'Deals - Procurement & Contracts"
            BannerImg = inMarketing3
            BannerDesc = "“in'Deals revolutionizes the retailing world & maximizes the retailers’ profit margins by empowering and granting them more control over their negotiations with Vendors.”"
            RiskArray = InDeals
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        default:
        break;
        }

        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    
                    <Content RiskArray = {RiskArray}/>
                </div>

                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar currentURL={currentURL}/> 
                </div>
            </div>
        </>
    )
}

export default Feature