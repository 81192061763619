import React from 'react'
import Logo from '../../../img/logo.png'
import {Link} from 'react-router-dom'
import {BsChevronRight, BsLinkedin} from 'react-icons/bs'
import {AiFillFacebook, AiFillTwitterSquare,} from 'react-icons/ai'
import Share from '../../../icons/share.png'
import Links from '../../../icons/links.png'
import { BsYoutube, BsTwitterX} from "react-icons/bs";
import { FaSquareXTwitter} from "react-icons/fa6";
import { FaFacebookSquare,FaLinkedin } from "react-icons/fa";
import { PiYoutubeLogoFill } from "react-icons/pi";
import { TbBrandYoutubeFilled } from "react-icons/tb";


const SideMenu = ({currentURL}) => {

  const solution = [
    {
      title: "in'Marketing Optimization",
      link : "/banking/feature?page=marketing-optimization"
    },
    {
      title: "in'Asset Management",
      link : "/banking/feature?page=inassetmanagement"
    },
    { 
      title: "in'Data Governance",
      link : "/banking/feature?page=indatagovernance"
    },
    {
      title: "in'Payment Services",
      link : "/banking/feature?page=financialservices"
    },
    {
      title: "in'Risk Compliance",
      link : "/banking/feature?page=risk-and-compliance"
    },
    {
      title: "in'Cards Merchant",
      link : "/banking/feature?page=cardsmerchant"
    },
    {
      title: "in'Analytics",
      link : "/analytics"
    },
    {
      title: "in'Crypto",
      link : "/banking/feature?page=crypto"
    },
    {
      title: "in'Kernel",
      link : "/banking/feature?page=inkernel"
    },
    {
      title: "in'Profit",
      link : "/banking/feature?page=inprofit"
    },
  ]

  const social = [
    {
      platform: <FaFacebookSquare/>,
      class: "facebook_icon",
      link:`https://www.facebook.com/sharer/sharer.php?u=${(currentURL)}`,
      color: '#1877F2',
    },
    // {
    //   platform: <AiFillTwitterSquare />,
    //   class: "twitter_icon"
    // },
    // {
    //   platform: <TbBrandYoutubeFilled />,
    //   class: "linkedin_icon",
    //   link:'https://youtube.com/@valoorescommunicationsoffi7749',
    //   color: '#FF0000',
    // },
    {
      platform: <FaLinkedin />,
      class: "linkedin_icon",
      link:`https://www.linkedin.com/sharing/share-offsite/?url=${currentURL}`,
      color: '#0077B5',
    },
    {
      platform: <FaSquareXTwitter/>,
      class: "twitter_icon",
      link:`https://twitter.com/intent/tweet?url=${currentURL}&text=Check%20this%20out!`,
      color: '#000',
    },
  ]

  const quickLinks = [
    { 
      title: "Become a Partner",
      link : "/clients"
    },
    {
      title: "Contact us",
      link : "/aboutUs?variable=Contact"
    },
    {
      title: "Spotlight News",
      // link : "https://valoores.com/spotlightnews.php",
      link : "/News&Events",
    },
    {
      title: "Job Application",
      // link : "https://hr.valoores.com/",
      link : "/hr/career/#jobs_scroll"
    },
  ]
  return (
    <>
      <div className="banking_sideMenu">
        {/* <div className="row banking_SideMenu-items">
          <h6>Request Offline Application</h6>
        </div> */}
        <div className="row banking_SideMenu-items">
          <h6 className='d-flex flex-row gap-2'>
            <img src={Logo} alt="logo" style={{width: '24px'}}/>
             VALOORES Solutions
          </h6>
          <ul style={{listStyle: 'none'}}>
            {solution.map((item, index) =>
              <li key={index}>
                <Link target='_blank' style={{textDecoration: 'none', }} to={item.link}>
                  <BsChevronRight />{item.title}
                </Link>
            </li>
            )}
          </ul>
        </div>
        <div className="row banking_SideMenu-items">
          <h6 className='d-flex flex-row gap-2'>
            <img src={Share} alt="logo" style={{width: '24px'}}/>
             VALOORES Solutions
          </h6>
          <div className='d-flex flex-row'>
            {social.map((item, index) =>
              <span key={index} className='social_span'>
                <Link target='_blank' className='d-flex' style={{textDecoration: 'none', fontSize: '35px'}} to= {item.link}>
                <span className={item.class} style={{ color: item.color }}>
                  {item.platform}
                </span>
                </Link>
              </span>
            )}
          </div>
        </div>
        <div className="row banking_SideMenu-items mt-5">
          <h6 className='d-flex flex-row gap-2'>
            <img src={Links} alt="logo" style={{width: '24px'}}/>
            Quick Links
          </h6>
          <ul style={{listStyle: 'none'}}>
            {quickLinks.map((item, index) =>
              <li key={index}>
                <Link target='_blank' style={{textDecoration: 'none', }} to={item.link}>
                  <BsChevronRight />{item.title}
                </Link>
            </li>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default SideMenu