import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import govSlides from './govSlides';

const SectionCarousel = ({ setActiveIndex }) => {
  const [activeIndex, setActiveIndexState] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex);
    setActiveIndex(activeIndex);
  }, [activeIndex, setActiveIndex]);


  const settings = {
    // autoplay: true,
    // autoplaySpeed: 3000,
    focus: false,
    easing: 'linear',
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveIndexState(next);
    },
  };

  const makeActive = (index) => {
    setActiveIndexState(index);
  };

  return (
    <>
      <div className='geo_container gov_container mt-5' style={{ position: 'relative' }}>
        <Slider {...settings} ref={sliderRef}>
          {govSlides.map((gov, index) => (
            <div className='geo_content' style={{ position: 'relative' , height: '100vh' }} key={index}>
              <div className="img_geo-content row" style={{ backgroundImage: `url(${gov.image})`, height: '100vh' }}>
                <div className="overlay_bg-gov"></div>
                <div className="text_geo-content w-75 col-md-4">
                  <h2 className='mb-4 text-white'>{gov.title}<br /></h2>
                  <p className='w-80'>{gov.description}</p>
                  <div className='w-75 d-flex flex-row align-items-center justify-content-center gap-3'>
                    {/* <Link className='w-25 btn btn-secondary btn-show'>Watch Demo</Link> */}
                    {/* {gov.button === 'yes' && 
                      <Link target="_blank" to='https://demo.valoores.com/valooresdashboards/' className='w-25 btn btn-secondary btn-show'>Request Demo</Link>
                    } */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className='geo_thumb d-flex justify-content-center text-white gap-1 align-items-center'>
          <div className="container flex-row d-flex w-75 align-items-center justify-content-center">
            {govSlides.map((gov, index) => {
              const icon = gov.icon; 
              const iconWhite = gov.icon_white; 
              return (
                <div
                  className={index === activeIndex ? 'geo_thumb-box active' : 'geo_thumb-box'}
                  onClick={() => makeActive(index)}
                  key={index}
                >
                  {/* <div className="overlay_bg"></div> */}
                  <img alt="icon"
                    style={{ width: '24px', height: '24px' }}
                    src={index === activeIndex ? icon : iconWhite} 
                  />
                  {gov.header}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionCarousel;
