import React, { useState } from 'react';
import applicationSection from './applicationSection';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import ReactModal from 'react-modal';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '320',
    width: '100%',
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

const Application = () => {
  const [isOpen, setIsOpen] = useState({});

  const openModal = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const closeModal = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  return (
    <>
      <div className="container app_container my-5">
        <div className="row text-center d-flex flex-column align-items-center justify-content-center">
          <h3>VALOORES Related Applications</h3>
          <h5>VALOORES Online & Offline Demos</h5>
        </div>
        <div className="d-flex align-items-center justify-content-around flex-lg-row flex-md-column flex-sm-column flex-xs-column text-center col-md-12">
          {applicationSection.map((items, index) => (
            <div key={index}>
              <img src={items.icon} alt="" />
              <h4 className="mt-4">{items.title}</h4>
              <p className="mt-4 text-start">{items.desc}</p>
              <span>
                <div className='links_app d-flex flex-column gap-3'>
                    <Link onClick={() => openModal(index)}> Watch Video </Link>
                    <Link to={items.more} >Read More </Link>
                    {/* <Link >Quick Demo </Link>
                    <Link >Request Demo </Link> */}
                </div>
                <ReactModal
                  className="d-flex align-items-center justify-content-center flex-column-reverse"
                  isOpen={isOpen[index]}
                  onRequestClose={() => closeModal(index)}
                  contentLabel="Example Modal"
                  appElement={document.getElementById('root')}
                >
                  <VideoPlayer videoId={items.video} />
                  <button onClick={() => closeModal(index)}>X</button>
                </ReactModal>
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Application;
