
const InV21 = [
    {
      title: "",
      subtitle:"",
      desc:
      (
        <>
          VALOORES announces the Next Generation v20 ecosystem that marches at the same drum with the latest Digital Transformation guidelines, to align with the metamorphosing Business models, benchmark Data Readiness, and explore Data Challenges when addressing Complex and Changing Business requirements.

          VALOORES R&D harnesses its value-based competencies to streamline its Technology stack and Design frameworks with the market’s latest trends, and leverages its road map emanating from our lessons learned with our progressive Tier 1 customers around the globe.
        </>
      ),
      list: [''],
    },
    {
        title: "VALOORES embraces the “SECOND WAVE” of FinTech, RegTech, & GovTech Trends",
        subtitle:"",
        desc: 'VALOORES embraces the “Second Wave” by focusing on the trends with highest potential within such a vibrant ecosystem.',
        list: [""
          
        ],
      },
    {
        title: "",
        subtitle:"VALOORES BI & AI - Machine Learning & Advanced Analytics",
        desc: 'VALOORES technology analyzes large data sets to identify patterns and respond to them. Descriptive, Diagnostic, Predictive, and Prescriptive Analytics are at the heart of the Analytics engine, to research human behavior and plot Behavioral Analytics. ',
        list: [""  
        ],
      },
    {
        title: "",
        subtitle:"VALOORES BLOCKCHAIN",
        desc: 'VALOORES BLOCKCHAIN saves back office costs by eliminating mistakes, increasing the efficiency of the ledger, and enhancing the transparency and security of data; all that as the P2P network of computers supporting the BLOCKCHAIN is decentralized, the transactions recorded are permanent and cannot be altered. Blocks (transactions) are added chronologically, Transactions are permanent and cannot be altered, enhancing the transparency and security of data.',
        list: [""  
        ],
      },
    {
        title: "",
        subtitle:"VALOORES Digital Identity",
        desc: 'VALOORES introduced its Digital ID framework using controls to monitor the identity of individual users of a network; VALOORES Digital Identity Management allows or limits access to systems and data, to simplify organizational operations and enhance customer experience.',
        list: [""  
        ],
      },
    {
        title: "",
        subtitle:"VALOORES Cybersecurity",
        desc: 'VALOORES delivers its Cybersecurity solutions, to help Financial Services Providers protect their systems from phishing, business email compromise, ransomware, and distributed denial of service (DDoS) attacks; all that, due to the heightened risk of cybersecurity threats. ',
        list: [""  
        ],
      },
    {
        title: "",
        subtitle:"VALOORES Robo Advisor",
        desc: 'VALOORES Robo Advisors provide automated advice to improve customer service with minimal human intervention, allow investment advisors target the mass affluent as a new customer base, among many other applications.',
        list: [""  
        ],
      },
    {
        title: "",
        subtitle:"VALOORES Robotics",
        desc: 'VALOORES Robotics is designed to apply logic driven algorithms, to perform high volume, low value added tasks, instead of humans. Robotic Process Automation (RPA) can manipulate data and interact with other systems, improving quality, speed and agility.',
        list: [""  
        ],
      },
    {
        title: "",
        subtitle:"VALOORES Digital Information Management",
        desc: 'VALOORES leverages Master Information Management with the Digital Asset Management (store, share, and organize digital assets in a central location), Consent Management (collect and handle users’ consents, manage data subjects’ requests and pass all consent-related data to the respective ad partners), and Mastering the Identity Management',
        list: [""  
        ],
      },
      {
        title: "VALOORES - A Brief Summary in few Lines",
        desc:'VALOORES empowers decision making, helping people and businesses around the globe reach their targets. Founded in 1989 in France, the Company is a pioneer in Master Data Governance, Retail & Merchandising, Supply Chain Optimization, KYC, Regulatory Compliance, Financial Crime - AML & Fraud, Predictive Analytics and Data Science to improve their on-going operations, executions and decisions.For more information, please visit tech.valoores.com or digital.valoores.com',
        list:
        [""],
      },
    //   {
    //     title: "",
    //     desc:'We put forward a list of pre-built MDM objects that will be used by the bank’s different systems and platforms as shared cleansed information structure. Below is a sample on some of the main structures dealing with the MDM principal conceptual entities:',
    //     list: 
    //     [
    //       "Party Main Information: identifying the attributes related to an individual or firms that exchanges information and services within the bank.",
    //       "Person Main Information: identifying the attributes related to an individual of interest to the bank.",
    //       "Company Main Information: identifying the attributes related to a separate legal entity which conducts operations for the bank in some geographical location. It is considered as a separate legal entity.",
    //       "Customer Main Information: identifying the attributes related to a person or individuals, who purchase, may purchase or have purchased services from the bank. The customer data model includes the management of the different segmentation supporting the customer behavior; this allows the bank to create as possible segmentation and customer grouping as the business needs.",
    //       "Location Main Information: identifying the attributes related to a physical place the bank conducts its business. It may be any or all of the defined sub-types: Work Location, Selling Location and Inventory Location. This entity groups the link with the address parameterization and the contacts dealing with the locations; knowing that the design of the location functions allows dividing not only the bank to branches and head offices but also to dividing the same branch into different locations such as floors, departments, etc.",
    //       "Dynamic Status Management: providing the bank/financial institution to parameterize the different statuses corresponding to the different business objects and processes available. Several other options are presented; with an essential facility lying in the fact that conditions can be set to allow the revolution from one status to a consecutive one.",
    //       "Account Main Information: identifying the attributes managed at the account definition level such as account number, related branch, related customer, ledger information, interest information, commission information…",
    //       "Product Main Information: identifying the attributes related to the lowest level of the product hierarchy retained within the bank. The same data model could be used for any types of products and services managed within the bank; where we centralize the creation of the cards, loans, insurance and other services in a centralized place differing by type.",
    //       "Setup Parameters: defined system parameters that rely on ISO Codification Lists and even other international standard lists such as Country, Country Currency, Country Divisions, Currency, Language, Measure Unit, Time Zone, etc.",
    //     ],
    // }
  ];
  
  export default InV21;
  