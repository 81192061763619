import pdf1 from '../../../ValooresNew/assets/pdf/Senior Software Architect.pdf';
import pdf2 from '../../../ValooresNew/assets/pdf/business-dev-eu.pdf';
import pdf3 from '../../../ValooresNew/assets/pdf/business-dev-gcc.pdf';
import pdf4 from '../../../ValooresNew/assets/pdf/business-dev-na.pdf';
import pdf5 from '../../../ValooresNew/assets/pdf/CyberSecuritySpecialist.pdf';
import pdf6 from '../../../ValooresNew/assets/pdf/Data Analyst- VALOORES.pdf';
import pdf7 from '../../../ValooresNew/assets/pdf/Data Modeler- System Integrator-VALOORES.pdf';
import pdf8 from '../../../ValooresNew/assets/pdf/data-analytics-lead.pdf';
import pdf9 from '../../../ValooresNew/assets/pdf/Data-Scientist-valoores.pdf';
import pdf10 from '../../../ValooresNew/assets/pdf/DatabaseAdministrator(DBA).pdf';
import pdf11 from '../../../ValooresNew/assets/pdf/CyberSecuritySpecialist.pdf';
import pdf12 from '../../../ValooresNew/assets/pdf/DevopsEngineer.pdf';
import pdf13 from '../../../ValooresNew/assets/pdf/QualityAssuranceEngineer.pdf';
import pdf14 from '../../../ValooresNew/assets/pdf/business-dev-gcc.pdf';
import pdf15 from '../../../ValooresNew/assets/pdf/project_manager.pdf';
import pdf16 from '../../../ValooresNew/assets/pdf/DatabaseAdministrator(DBA).pdf';
import pdf17 from '../../../ValooresNew/assets/pdf/CyberSecuritySpecialist.pdf';
import pdf18 from '../../../ValooresNew/assets/pdf/OracleDeveloper.pdf';
import pdf19 from '../../../ValooresNew/assets/pdf/webDev.pdf';
import pdf20 from '../../../ValooresNew/assets/pdf/Web Developer.pdf';
import pdf21 from '../../../ValooresNew/assets/pdf/V.2 Database Integrator.pdf';
import pdf22 from '../../../ValooresNew/assets/pdf/senior-web-designer.pdf';
import pdf23 from '../../../ValooresNew/assets/pdf/junior-it.pdf';
import pdf24 from '../../../ValooresNew/assets/pdf/Software Developer.pdf';
import pdf25 from '../../../ValooresNew/assets/pdf/MachineLearningEngineer.pdf';
import pdf26 from '../../../ValooresNew/assets/pdf/business-dev-na.pdf';
import pdf27 from '../../../ValooresNew/assets/pdf/business-dev-eu.pdf';
import pdf28 from '../../../ValooresNew/assets/pdf/Junior-Business-Analyst.pdf';
import pdf29 from '../../../ValooresNew/assets/pdf/Senior Business Analyst-1.pdf';

const JobsArray = 
[
    {
        category:'Engineering And Design',
        title:'Software Architect',
        desc: 'We are looking for a highly skilled Software Architect to design and oversee the development of high-quality software solutions. The ideal candidate will have a strong technical background, experience in software architecture, and the ability to guide development teams in implementing advanced, scalable and secure software systems.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf1,
        order: '',
        level: 'Senior',
        responsibilities: "<ul><li>Design and implement software architecture that meets business and technical requirements.</li><li></li>Define technical standards, best practices, and architectural guidelines.</li><li>Collaborate with stakeholders, developers, and product managers to translate business needs into technical solutions.</li><li>Lead the selection of appropriate technologies, frameworks, and tools.</li><li>Ensure software solutions are scalable, maintainable, and secure.</li><li>Conduct code reviews and provide technical mentorship to development teams.</li><li>Identify and resolve architectural and performance issues.</li><li>Stay updated with emerging technologies and industry trends.</li>",
    },
    {
        category:'Engineering And Design',
        title:'DevOps Engineer',
        desc: 'Devops engineer will be heavily involved in setup, management and monitoring of infrastructure,in automation, integration, code validation, and other systems-related tasks. He/She will belinking with various team members and clients across projects and producing documentation as his work progresses.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf12,
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Engineering And Design',
        title:'Software Engineer / Developer',
        desc: 'We are looking for a Software Developer/Engineer with experience in building high-performing, scalable, enterprise-grade applications. The new candidate will be part of a talented software team that works on mission-critical applications. Software developer roles and responsibilities include managing Java/Java EE application development while providing expertise in the full software development lifecycle, from concept and design to testing.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf24,
        order: '',
        level: 'Junior / Intermediate',
    },
    // {
    //     category:'Engineering And Design',
    //     title:'Software Engineer ',
    //     // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
    //     location: 'Bauchrieh',
    //     link: 'https://form.jotform.com/250120962329452',
    //     // linkto:pdf3,
    //     order: '',
    //     level: 'Junior / Intermediate',
    // },
    {
        category:'Engineering And Design',
        title:'Data Analytics Lead',
        desc:'Data Analytics Lead',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf8,
        order: '',
        level: 'Senior',
    },
    {
        category:'Engineering And Design',
        title:'Data Scientist',
        // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf9,
        order: '',
        level: 'Junior / Intermediate',
    },
    {
        category:'Engineering And Design',
        title:'Quality Assurance Engineer',
        desc: 'The Senior Software QA Engineer will work in coordination with the Software Development Leads to monitor every phase of the development process to ensure that the design and software adhere to company standards. The successful candidate will help meet deadlines by breaking up the development process into attainable testing goals and relaying any issues back to the development and product teams or leaders.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf13,
        order: '',
        level: 'Senior',
    },
    {
        category:'Engineering And Design',  
        title:'Machine Learning Engineer',
        desc: 'We are looking for candidates with a BS, MS, or Ph.D. in computer science or related Engineering fields to join the core machine learning and data engineering team. The team works on problems related to NLP, Biometric Securities, Transaction monitoring, fraud/anomaly detection, and many others. As part of this team, you will be focused on building, deploying, monitoring, and maintaining the next generation of our models and algorithms. You will collaborate with other researchers, software engineers, and product managers to deliver models with state-of-the-art accuracy.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf25,
        order: '',
        level: 'Junior / Intermediate/ Senior',
    },
    {
        category:'Business Development',
        title:'Business Development Regional - North America',
        // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf26,
        order: '',
        level: 'Senior',
    },
    {
        category:'Business Development',
        title:'Business Development Regional - Europe',
        // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf27,
        order: '',
        level: 'Senior',
    },
    {
        category:'Business Development',
        title:'Business Development Regional - GCC',
        // desc: 'Business Development Manager – Regional GCC',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf14,
        order: '',
        level: 'Senior',
    },
    {
        category:'Product Management',
        title:'Project Manager',
        desc: 'We are seeking a Project Manager with extensive experience in directing teams and overseeing information and communication technology projects. The ideal candidate will have excellent information technology and communication skills, with a proven track record of problemsolving and innovation in the software solutions industry for Financial Services (Banking & Insurance), Government, and Retail sectors. The Project Manager will lead teams through the planning, implementation, and completion of projects, ensuring adherence to timelines and budgets.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf15,
        order: '',
        level: 'Senior',
    },
    {
        category:'Product Management',
        title:'Business Analyst',
        // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf28,
        order: '',
        level: 'Junior',
    },
    {
        category:'Product Management',
        title:'Business Analyst',
        // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf29,
        order: '',
        level: 'Senior',
    },
    {
        category:'Information Technology',
        title:'Web Designer',
        // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf22,
        order: '',
        level: 'Senior',
    },
    {
        category:'Information Technology',
        title:'Database Administrator(DBA)',
        desc: 'The Database Administrator (DBA) is responsible for the performance, integrity, and security of the university’s information systems’ databases. The DBA provides technical expertise in the design, implementation, and maintenance of database management systems that support institutional business and clinical applications. Additional responsibilities include reporting, data input and output, technology management, and end-user training and support.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf16,
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Cyber Security Specialist',
        desc: 'We are looking for a dedicated and meticulous cybersecurity specialist to join our growing team of Information and Operations Technology professionals. In the role you will operate independently and as part of a team to ensure our software, hardware, and related components are protected from cyberattacks. The job description will include developing security systems, analyzing current systems for vulnerabilities, and maintaining up-to-date software/firmware technologies for networked enterprise and industrial devices.Candidates should have strong IT skills and a deep understanding of computer/network vulnerabilities',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf17,
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Oracle Developer',
        desc: 'System Integrator, is expected first to represent the company in various meetings and workshops with the clients related to technical implementation requirements (hardware and software) and data integration/migration requirements (initial load, ongoing inbound, ongoing outbound). In addition, he/she is expected to initiate, plan, monitor and control the execution of the various activities related to the implementation and data integration on projects and secure their proper installation and execution under the client environments',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf18,
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Weblogic / Websphere / Tomcat Administrator',
        desc: 'In the role of Tomcat/Websphere/Weblogic Administrator, you will act as subject matter expertise and provide support for Global Technology Web administration on Unix/Linux/Windows platforms. You will assist with capacity planning, administration, troubleshooting issues and performance tuning of Web/AppServers like WebLogic Server, WebSphere Application Server, Tomcat, Apache, IIS as well as DataPower. We need strong Unix and other scripting skills as well.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf19,
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Web Developer',
        desc: 'We are looking for an experienced mid level Web developer to join our Developers team. You will be responsible for the server side of our web applications. If you have excellent programming skills and a passion for developing applications or improving existing ones, we would like to meet you. As a Web developer, you’ll work closely with our engineers to ensure system consistency and improve user experience. Ultimately, you should be able to develop and maintain functional and stable web applications to meet our company’s needs.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf20,
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'Database Integrator',
        desc: 'We are seeking a Database Integrator who will represent the company in client meetings and workshops related to technical implementation and data integration requirements. The candidate will initiate, plan, monitor, and control the execution of activities related to implementation and data integration projects, ensuring proper installation and execution under client environments.',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf21,
        order: '',
        level: 'Intermediate',
    },
    {
        category:'Information Technology',
        title:'IT',
        // desc: 'Are you passionate about 3D mapping and SDKs to build web apps? At Valoores Jdeideh we are looking for a result-driven product engineer ...',
        location: 'Bauchrieh',
        link: 'https://form.jotform.com/250120962329452',
        linkto:pdf23,
        order: '',
        level: 'Junior / Intermediate',
    },
]

export default JobsArray;