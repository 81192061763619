import React from 'react';
import { Link } from 'react-router-dom';
import { BsYoutube, BsTwitterX} from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import GoogleSearch from './GoogleSearch';

const Footer = () => {
  let year = new Date().getFullYear();
  const footer1 = [
      {
        title: "ABOUT VALOORES",
        subfooter: [
          {
          subtitle: "Careers",
          linkSub: "/hr/career",
          },
          {
          subtitle: "Events",
          linkSub: "/News&Events",
          },
          
          // {
          // subtitle: "Quotes",
          // linkSub: "http://valoores.com/quotes.php",
          // }
        ]
      },
      {
        title: "CONTACT US",
        subfooter: [
        // {
        // subtitle: "Access Dashboards",
        // linkSub: "https://demo.valoores.com/valooresdashboards",
        // },
        {
        subtitle: "Office Locations",
        // linkSub: "https://valoores.com/contactus.php",
        linkSub: `/aboutUs?variable=Contact`,
        },
        // {
        // subtitle: "E-mail",
        // linkSub: `/aboutUs?variable=Contact`,
        // }
        {
          subtitle: "E-mail",
          linkSub: `mailto:info@valoores.com`,
        }
      ]
    }
  ];

  const footer2 =[
  {
    title: "LINES OF BUSSINESS",
    subfooter: [
        {
        subtitle: "Banking",
        linkSub: "/banking",
        },
        {
        subtitle: "Analytics",
        linkSub: "/analytics",
        },
        // {
        // subtitle: "Technology",
        // linkSub: "/technology",
        // },
        {
        subtitle: "Academy",
        linkSub: "/hr/academy",
        },
        {
        subtitle: "Insurance",
        linkSub: "/insurance",
        },
        {
        subtitle: "Retail",
        linkSub: "/Geospatial/retail-data",
        },
        {
        subtitle: "Healthcare",
        linkSub: "/solutions/healthcare",
        },
        {
        subtitle: "Multimedia",
        linkSub: "/Multimedia",
        },
        {
        subtitle: "Government",
        linkSub: "/solutions/gov",
        },
        // {
        // subtitle: "Webinars",
        // linkSub: "https://webinar.valoores.com/",
        // },
  
      ]
    }
  ];

    const footer3=[{
      title: "SERVICES",
      subfooter: [
        {
        subtitle: "AML",
        linkSub: "/banking/riskandcompliance?variable=Aml",
        },
        {
        subtitle: "Fraud Management",
        linkSub: "/banking/riskandcompliance?variable=infraud",
        },
        {
        subtitle: "Regulatory",
        linkSub: "/banking/riskandcompliance?variable=regulatoryreportingsolution",
        },
        {
        subtitle: "Via",
        linkSub: "/banking/inAnalytics?variable=inVia",
        },
        {
        subtitle: "Merch",
        linkSub: "/banking/feature?page=cardsmerchant",
        },
        // {
        // subtitle: "Consultancy",
        // linkSub: "https://banking.valoores.com/consultancyservices.php",
        // },
        // {
        // subtitle: "IRFP",
        // linkSub: "https://banking.valoores.com/irfp.php",
        // },
        {
        subtitle: "Profit",
        linkSub: "/banking/feature?page=inprofit",
        },
        {
        subtitle: "AI/BI",
        linkSub: "/banking/inAnalytics?variable=inBusiness",
        },
        {
        subtitle: "Campaign",
        linkSub: "/banking/inMarketing?variable=InCampaignManagement",
        },
        {
        subtitle: "KYC",
        linkSub: "/banking/InDataA?variable=InKYC",
        },
        {
        subtitle: "IFRS9",
        linkSub: "/banking/riskandcompliance?variable=IFRS9",
        },
  
      ]
    },
  ];

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row text-white">
            <div className="col-md-4 foot_content">
              <div className=' d-flex flex-direction-row'>
              {footer1.map((items, index) => (
                <div key={index} className='sub_footer p-3'>
                  <h6 className='footer_title'>{items.title}</h6>
                  <ul className={items.subfooter.length > 5 ? 'more-than-five' : ''}>
                    {items.subfooter.map((subfooter, subIndex) => (
                      <li key={subIndex}>
                        <Link target="_blank"  to={subfooter.linkSub}>{subfooter.subtitle}</Link>
                      </li>                              
                    ))}
                  </ul>
                </div>
              ))}
            </div>
              <div className="footer_search">
                {/* <input type="search" autoComplete="off" placeholder="Search" name="Search" id="search_input"/> */}
              <GoogleSearch/>
              </div>
            </div>
            <div className="col-md-4 foot_content">
            {footer2.map((items, index) => (
              <div key={index} className='sub_footer p-3'>
                <h6 className='footer_title'>{items.title}</h6>
                <ul className={items.subfooter.length > 5 ? 'more-than-five' : ''}>
                  {items.subfooter.map((subfooter, subIndex) => (
                    <li key={subIndex}>
                      <Link target="_blank"  to={subfooter.linkSub}>{subfooter.subtitle}</Link>
                    </li>                              
                  ))}
                </ul>
              </div>
            ))}
            </div>
            <div className="col-md-4 foot_content">
            {footer3.map((items, index) => (
              <div key={index} className='sub_footer p-3'>
                <h6 className='footer_title'>{items.title}</h6>
                <ul className={items.subfooter.length > 5 ? 'more-than-five' : ''}>
                  {items.subfooter.map((subfooter, subIndex) => (
                    <li key={subIndex}>
                      <Link target="_blank"  to={subfooter.linkSub}>{subfooter.subtitle}</Link>
                    </li>                              
                  ))}
                </ul>
              </div>
            ))}
            </div>
          </div>
          <div className="row">
            <div className="footer_socials">
              <div className="footer_socials-icon d-flex align-items-center justify-content-center gap-2">
                  <Link to="https://youtube.com/@valoorescommunicationsoffi7749" className="social_icons d-flex justify-content-center align-items-center"><BsYoutube className="youtube"></BsYoutube></Link>
                  <Link to="https://x.com/valoores" className="social_icons d-flex justify-content-center align-items-center"><BsTwitterX className="twitter"></BsTwitterX></Link>
                  <Link to="https://www.linkedin.com/company/valoores/" className="social_icons d-flex justify-content-center align-items-center"><FaLinkedinIn className="linkedin"></FaLinkedinIn></Link>
              </div>
            </div>
          </div>
        </div>
          <div className="row mt-4 footer_copyright">
            <ul className="copyright_content d-flex flex-row align-items-center gap-3">
                <li>
                    <Link target="_blank" href="" style={{color: 'rgb(255, 255, 255)',cursor:'default'}}>   
                      Copyright © VALOORES {year}. All rights reserved.
                    </Link> 
                </li>
                <li>
                    <Link to='/LetterOfCeo' target="_blank" href="" style={{color: 'rgb(255, 255, 255)'}}>   
                        CEO'S Letter
                    </Link> 
                </li>
                <li>
                    <Link to='/TermsAndCondition' target="_blank" href="" style={{color: 'rgb(255, 255, 255)'}}>   
                        Terms &amp; Conditions
                    </Link> 
                </li>
                <li>
                    <Link to='/Privacy' target="_blank" style={{color: 'rgb(255, 255, 255)'}}>   
                        Privacy Policy
                    </Link> 
                </li>
                <li>
                    <Link target="_blank" to="/aboutUs?variable=Contact" style={{color: 'rgb(255, 255, 255)'}}>   
                        Contact Us
                    </Link> 
                </li>
                {/* <li>
                    <Link target="_blank" to="./admin" style={{color: 'rgb(255, 255, 255)'}}>   
                        Admin
                    </Link> 
                </li> */}
            </ul>
          </div>
      </div>
    </>
  )
}

export default Footer