import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import LogoOrg from "../img/full_white.png";
import LogoColored from "../img/full_logoC.png";
import { Link, useLocation } from "react-router-dom";
import { FaSearch, FaChevronRight, FaUserAlt } from "react-icons/fa";
import { CgMenuGridR } from "react-icons/cg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import $ from "jquery";
import slides from "./slides";
import Carousel from "./index/Carousel";
import Modal from "react-modal";

// Modal.setAppElement('#root');
import SolutionsArray from "./menus/SolutionsArray";

const Navbar = () => {
  const location = useLocation();
  const shouldRenderCarouselMenu = location.pathname.slice(-1);

  const [selectedSlide, setSelectedSlide] = useState();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isMobileSubMenu, setIsMobileSubMenu] = useState(false);
  // const fetchedData = Axios({ path: '/carousel' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isElligible, setIsElligible] = useState(false);
  const [isMindMap, setIsMindMap] = useState(false);
  // const [logo, setLogo] = useState(LogoOrg);
  let isLoggedIn;
  const handleMobileMenuSub = () => {
    setIsMobileSubMenu(true);
  };

  const handleLogin = () => {
    if (
      (username === "ValAdmin" && password === "ValAdmin") ||
      isLoggedIn === true
    ) {
      setIsElligible(true);
      setIsModalOpen(false);
      localStorage.setItem("isLoggedIn", true);
      isLoggedIn = localStorage.getItem("isLoggedIn");
    } else {
      localStorage.setItem("isLoggedIn", false);
      setIsElligible(false);
      isLoggedIn = localStorage.getItem("isLoggedIn");
    }
  };

  const handleModalOpen = (clicked) => {
    if (!isElligible) {
      setIsModalOpen(true);
      setIsMindMap(false);
    } else {
      setIsMindMap(true);
      setIsModalOpen(false);

      if (clicked === "logout") {
        setIsMindMap(false);
        setIsElligible(false);
        localStorage.setItem("isLoggedIn", false);
      }
    }

    if (clicked === "") {
      setIsMindMap(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // const handleLogout = () => {
  //   console.log('isElligible');
  //   setIsElligible(false);
  //   // window.location.reload();
  // }

  // const handleLogoutMenu = () => {
  //   setIsElligible(false);
  //   // window.location.reload();
  // }

  const handleSubMindMenuOpen = (prev) => {
    setIsMindMap(!prev);
  };

  const SolutionsMenu = () => {
    return (
      <>
        {SolutionsArray.map((solution, index) => (
          <>
            <h1>{solution.title}</h1>
          </>
        ))}
      </>
    );
  };

  // const SubMenu = () => {
  //   return (
  //     <ul className='main_menu-items'>
  //       <li>Contact Us</li>
  //       <li>Word Of The CEO</li>
  //       <li>Mission & Vision</li>
  //       <li>
  //         <Link target="_blank" to="/aboutUs/leaders">
  //           Executive Leadership
  //         </Link>
  //       </li>
  //       <li>
  //         <Link target="_blank" to="/aboutUs/leaders">
  //           Executive Leadership
  //         </Link>
  //       </li>
  //     </ul>
  //   );
  // };

  const openMenu = () => {
    $("#menu_img").toggle();
  };

  const handleSlideClick = (slideIndex) => {
    setSelectedSlide(slideIndex);
    $("#menu_img").toggle();
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");

      if (window.scrollY > 150) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleUsername = (input) => {};

  const handleSubMenuOpen = () => {
    console.log("hiiiii");
  };

  return (
    <>
      <div className="navbar" id="navbar">
        <div className="container_content">
          <div className="left_links">
          <div id="mobile_view" className="mobile_menu">
  <AiOutlineMenu
    onClick={() => setIsNavExpanded(!isNavExpanded)}
    style={{ position: 'absolute', zIndex: '1' }}
  />
  <div className={isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'}>
    <ul>
      <li>
        <Link target="_blank" to="/#business_scroll">
          Solutions
        </Link>
      </li>
      <li>
        <Link target="_blank" to="/#services_scroll">
          Services
        </Link>
      </li>
      <li>
        <Link target="_blank" to="/#partners_scroll">
          References
        </Link>
      </li>
      <li onClick={() => setIsMobileSubMenu(!isMobileSubMenu)}>
        <span>About Us</span>
        {isMobileSubMenu && (
          <ul className="submenu">
            <Link target="_blank" to='/News&Events'>
              <li>Events</li>
            </Link>
            <Link target="_blank" to='/LetterOfCeo'>
              <li>CEO'S Letter</li>
            </Link>
            <Link target="_blank" to={`/aboutUs?variable=Contact`}>
              <li>Contact Us</li>
            </Link>
            <Link target="_blank" to='/hr/career'>
              <li>Recruitment</li>
            </Link>
            <Link target="_blank" to='/hr/academy'>
              <li>Academy</li>
            </Link>
            <Link target="_blank" to='/aboutUs/logo'>
              <li>The Logo</li>
            </Link>
          </ul>
        )}
      </li>
    </ul>
              </div>
            </div>
            <div className="logo">
              <Link target="_blank" to="/">
                <img id="logo_img" src={LogoOrg} alt="" />
              </Link>
            </div>
            <div className="links" id="pc_view">
              <Link
                className="link"
                to="/#business_scroll"
                spy={true}
                smooth={true}
                offset={-70}
                duration={50}
              >
                <h6>Solutions</h6>
              </Link>
              <Link
                className="link"
                to="/#services_scroll"
                spy={true}
                smooth={true}
                offset={-70}
                duration={50}
              >
                <h6>Services</h6>
              </Link>
              <Link
                className="link"
                to="/#partners_scroll"
                spy={true}
                smooth={true}
                offset={-70}
                duration={50}
              >
                <h6>References</h6>
              </Link>
              <Link
                className="link"
                to="/#media"
                spy={true}
                smooth={true}
                offset={-70}
                duration={50}
              >
                <h6>Media</h6>
              </Link>
              <div className="link" id="open_menu">
                <h6>About Us</h6>
                <ul className="submenu">
                  <Link target="_blank" to="/News&Events">
                    <li>Events</li>
                  </Link>
                  <Link target="_blank" to="/LetterOfCeo">
                    <li>CEO'S Letter</li>
                  </Link>
                  <Link target="_blank" to={`/aboutUs?variable=Contact`}>
                    <li>Contact Us</li>
                  </Link>

                  {/* <Link target="_blank" to='https://hr.valoores.com/'> */}
                  <li
                    className="d-flex flex-row align-items-center justify-content-between"
                    id="open_hrMenu"
                  >
                    HR
                    <FaChevronRight />
                    <ul className="subHrMenu" id="subHrMenu">
                      <Link target="_blank" to="/hr/career">
                        <li>Recruitment</li>
                      </Link>
                      <Link target="_blank" to="/hr/academy">
                        <li>Academy</li>
                      </Link>
                    </ul>
                  </li>
                  {/* </Link> */}

                  {/* <Link target="_blank" to='https://valoores.com/thelogo.php'> */}
                  <Link target="_blank" to="/aboutUs/logo">
                    <li>The Logo</li>
                  </Link>

                  {/* <Link target="_blank" to={`/aboutUs?variable=Ceo`}>
                    <li>Word Of The CEO</li>
                  </Link> */}

                  {/* <Link target="_blank" to={`/aboutUs?variable=Mission`}>
                    <li>Mission and Vision</li>
                  </Link> */}

                  {/* <Link target="_blank" to={`/aboutUs?variable=Leaders`}>
                    <li>Executive Leadership</li>
                  </Link> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="side_links">
            {/* <span>
              <FaSearch />
            </span> */}
            <div>
              <span
                onClick={handleModalOpen}
                className={
                  shouldRenderCarouselMenu === "/" ? "" : "hide_carousel"
                }
              >
                <FaUserAlt />
              </span>
              {isMindMap && isElligible && (
                <ul className="subMenuMind">
                  <Link
                    to="./MindMap"
                    onClick={() => handleModalOpen("closeSubMenu")}
                    target="_blank"
                    style={{ textDecoration: "none", color: "#777" }}
                  >
                    <li>Mind Map</li>
                  </Link>
                  <li onClick={() => handleModalOpen("logout")}>Logout</li>
                </ul>
              )}
            </div>
            <span
              className={
                shouldRenderCarouselMenu === "/" ? "" : "hide_carousel"
              }
            >
              <CgMenuGridR
                onClick={openMenu}
                className={"carousel_items"}
                style={{
                  fontSize: "26px",
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div id="menu_img">
        <div className="row">
          {slides.map((slide, index) => (
            <div className="col-md-4 mb-3" key={index}>
              <div className="goTo" onClick={() => handleSlideClick(index)}>
                <img src={slide.image} alt="img" />
              </div>
            </div>
          ))}
        </div>
      </div>

      {(location.pathname === "/" || location.pathname === "/trial") && (
        <Carousel selectedSlide={selectedSlide} />
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalOpen}
        contentLabel="Add Modal"
        className="ReactModal__Content_navbar"
        style={{ top: "30%!important" }}
      >
        <div className="">
          <div className="">
            <div className="">
              {/* <div>
                  <h5 className="pb-3 px-3">Add carousel</h5>
                </div> */}
              <div className="row px-4 d-flex flex-column align-items-center justify-content-center">
                <span
                  onClick={handleModalClose}
                  className="text-end clodeModal"
                  style={{ cursor: "pointer" }}
                >
                  X
                </span>
                <img src={LogoColored} className="w-75 mb-5" alt="" />
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={handleUsernameChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-12 mt-4">
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="px-4 d-flex justify-content-center gap-3 mt-5">
                <button className="btn btn-danger w-75" onClick={handleLogin}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
