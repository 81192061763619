import React , { useState} from 'react'
import {BsChevronUp} from 'react-icons/bs'
import {AiOutlineMinus} from 'react-icons/ai'
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';


const ContactUs = ({Ip}) => {
    
    const [isContactOpen, setIsContactOpen] = useState(false)
    const [isEmptyError, setIsEmptyError] = useState(false);
    
    
    const check = async () => {
        const contact_name = $('#contact_name').val();
        const contact_email = $('#contact_email').val();
        const contact_phone = $('#contact_phone').val();
        const contact_message = $('#contact_message').val();
      
        if (!contact_name || !contact_email || !contact_phone || !contact_message) {
          setIsEmptyError(true);
          return; 
        }
      
        try {
          const response = await fetch(`${Ip}controllers/phpmailer.php`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: contact_name,
              email: contact_email,
              phonenb: contact_phone,
              message: contact_message,
            }),
          });
      
          if (response.ok) {
            const result = await response.json();
            console.log('Email sent successfully:', result);
        
            $('#contact_name').val('');
            $('#contact_email').val('');
            $('#contact_phone').val('');
            $('#contact_message').val('');
            
            Swal.fire({
              icon: 'success',
              title: 'Email Sent!',
              text: 'Your message has been sent successfully.',
              confirmButtonText: 'Okay',
              confirmButtonColor:'#051C2C',
            });
          } else {
            console.error('Error in sending email:', response.statusText)
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong while sending your message.',
            });
          }
        } catch (error) {
          console.error('Error in sending email:', error);
          // Show an error alert in case of a network error
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error while processing your request. Please try again.',
          });
        }
      };
      
      
    const handleOpenContact = () => {
        setIsContactOpen(prevState => !prevState);
    }
    
    const ContactMenu = () => {
        return(
            <div className='contact__container-form contact_popup'>
                <div className='contact__container-form_head'>
                <div className="contact__container-form_title d-flex align-items-center">
                    <div>
                        <h5 className='text-white'>Contact us</h5>
                    </div>
                    <AiOutlineMinus onClick={handleOpenContact} className='close_contact-modal' style={{cursor: 'pointer'}}/>
                </div>
                
                <div className="p-3 d-flex flex-column gap-3">
                    <p className='text-dark ' style={{fontSize:'16px'}}>There are no online agents available right now to take your call. Please leave a message and we will reply by email.</p>

                    <div className="d-flex flex-column gap-2">
                    <input
                        type="text"
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Name"
                        id="contact_name"
                        // value={nameValue}
                        // onChange={handleNameChange}
                    />

                    <input
                        type="text"
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Email"
                        id="contact_email"
                        // value='defaultValue'
                        // onChange={handleEmailChange}
                    />

                    <input
                        type="text"
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Phone Number (optional)"
                        id="contact_phone"
                        // value={contactValue}
                        // onChange={handleContactChange}
                    />

                    <textarea
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Type your message"
                        id="contact_message"
                        // value={messageValue}
                        // onChange={handleMessageChange}
                    ></textarea>

                    </div>
                    {isEmptyError && <span className='err-msg'>Please Fill All Fields</span>}
                    <div className='d-flex flex-row justify-content-end'>
                        <button id='send_btn' className="btn btn-primary" style={{backgroundColor:'#051C2C',borderColor:'#051C2C'}}onClick={check}>Send</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    
    return (
    <>
        <div className="contact_us" onClick={handleOpenContact}>
            Contact Us 
            <BsChevronUp />
        </div>   
        <div>
            {isContactOpen && <ContactMenu />}
        </div>
    </>
  )
}

export default ContactUs