import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AmlHero from '../../../img/Aml/vdfs-hero.jpg';
// import { Link } from 'react-router-dom';
import geoSlides from '../../geo_slides';

const Carousel = ({ setActiveIndex }) => {
  const [activeIndex, setActiveIndexState] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex);
    setActiveIndex(activeIndex);
  }, [activeIndex, setActiveIndex]);

  const settings = {
    // autoplay: true,
    // autoplaySpeed: 3000,
    focus: false,
    easing: 'linear',
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveIndexState(next);
    }
  };

  const makeActive = (index) => {
    
      setActiveIndexState(index);
  };

  return (
    <>
      <div className='geo_container' style={{height:'100vh'}} >
        <Slider {...settings} ref={sliderRef}>
          {geoSlides.map((geo, index) => (
            <div className='geo_content' key={index}>
              <div className="img_geo-content row" style={{ backgroundImage: `url(${AmlHero})`,margin: '0', padding: '0', height: '100vh'}}>
                <div className="overlay_bg"></div>
                <div className="text_geo-content text-start position-absolute col-md-6 w-50" style={{top: '15%', left: '5%'}}>
                  <div>
                    <div className='d-flex flex-column'>
                      <h1 className='mb-3 text-white'>VFDS</h1>
                      <h2 className='text-white'>The fusion of Technology and Data</h2>
                    </div>
                    <div className='d-flex flex-column'>
                      <h2 className='mb-3 text-white'>A Solution for Every Need…</h2>
                      <h2 className='text-white'>A holistic and nimble Compliance Solutions Suite catering for all financial institutions: </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* <div className='geo_thumb d-flex justify-content-center text-white gap-1 align-items-center'>
          {geoSlides.map((geo, index) => (
            <div
              className={index === activeIndex ? 'geo_thumb-box active' : 'geo_thumb-box'}
              onClick={() => makeActive(index)}
              key={index}
            >
              <div className="overlay_bg"></div>
              <span style={{fontSize : '1.5rem'}}>{geo.icon}</span>
              {geo.title}
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default Carousel;
