import React from 'react'
import {Link} from 'react-router-dom'
import './Banner.css'
const Banner = (props) => {
  return (
    <div className='banner_img-risk text-white d-flex align-items-center justify-content-center' style={{ backgroundImage: `url(${props.img})`, position: "relative"}}>
      <div className="banner_overlay-ai"></div>
      <div className='w-100 flex-column' style={{top: '20%', position: "absolute"}}>
        <div className="container banner_content-ai flex-row">
            <div className="left_side" style={{}}>
                {/* <h1 style={{lineHeight : '4rem'}}>{props.title}</h1> */}
                <p style={{lineHeight : '2.5rem'}}>{props.desc}</p>
            </div>
            <div className="right_side" style={{flex : '1.5'}}>
            </div>
        </div>
        <div className="container mt-4 d-flex flex-column gap-3" style={{position: 'relative'}}>
          <div className="row">
            <div className="col-md-6 d-flex flex-row gap-3">
              {props.demo === 'yes' && <Link className="btn btn-danger">Watch Demo</Link>}
              {props.pdf === 'yes' && <Link className="btn btn-success">Executive Letter - Compliance Services</Link>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex flex-row gap-3">
              {props.fact === 'yes' && <Link className="btn btn-primary">Fact Sheet</Link>}
              {props.paper === 'yes' && <Link className="btn btn-secondary">Download White Paper</Link>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner