import Financial from '../../img/carousel/financial.jpg';
import Retail from '../../img/carousel/retail.jpg';
import Geospatial from '../../img/carousel/geospatial.jpg';
import Geo from '../../img/carousel/geo.jpg';
import AI from '../../img/carousel/ai.jpg';
import Government from '../../img/carousel/goverment.jpg';
import Bigdata from '../../img/carousel/bigdata.jpg';
import Knowledge from '../../img/carousel/knowledge.jpg';
import Slide1 from '../../img/carousel/slide1.jpg';
import Slide6 from '../../img/carousel/slide6.jpg';
// import Slide7 from '../../img/carousel/slide7.jpg';
// import Slide10 from '../../img/carousel/slide10.jpg';

const slides = [
  {
    image: Slide1,
    title: "in'Fraud Management",
    description: "Fast Fraud Detection and Prevention",
    position: 'bankigSlidePos',
    link: 'https://analytics.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Geospatial,
    title: '',
    description: (
      <>
        Whether you're a small business owner or a multinational retail corporation.
      </>
    ),
    position: 'slideGeoPos',
    link: '/Geospatial',
    button: 'yes',
    document: 'no'
  },
  {
    image: Geo,
    title: '',
    description: "We have gone beyond basic location data, which simply tells us where things are.",
    position: 'slidePos',
    link: 'https://indatacrowd.ai/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Retail,
    title: '',
    description: 'Valoores uses supply chain technologies and advanced retail services for responding to market needs.',
    position: 'slidePos',
    link: 'https://retail.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: AI,
    title: '',
    description: "AI is the cornerstone of our software solutions at Valoores. ",
    position: 'slideAiPos',
    link: '/ai',
    button: 'yes',
    document: 'no'
  },
  {
    image: Knowledge,
    title: "",
    description: "Poor data quality can cost businesses millions, but Valoores can help mitigate.",
    position: 'slidePos',
    link: 'https://indatacrowd.ai/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Bigdata,
    title: '',
    description: (
        'A wealth of information creates a poverty of attention.'
    ),
    position: 'slideBigPos',
    link: '',
    button: 'yes',
    document: 'no'
  },
  {
    image: Government,
    title: '',
    description: 'Valoores solutions facilitate the use of information and communication technologies to provide and improve public-sector services',
    position: 'slidePos',
    link: 'https://gov.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Financial,
    title: '',
    description: 'Valoores Delivers intelligent financial solutions and productivity-enhancing case management tools for financial institutions.',
    position: 'slidePos',
    link: 'https://banking.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Slide6,
    title: '',
    description: (
          "We offer a comprehensive suite of services that enable organizations to connect, captivate, and collaborate effectively in today's dynamic digital landscape."
    ),
    position: 'slideMultiPos',
    link: 'https://multimedia.valoores.com/',
    button: 'yes',
    document: 'no'
  },
];





  export default slides;