import React from 'react'
import Carousel from './CustomCarousel';
import { Helmet } from 'react-helmet';
import Choice from '../Banking/Choice';
import Paralax from '../Banking/Paralax';
import Application from '../Banking/Application';
import Sections from '../Banking/Sections';
import dashboardImg from '../../img/dashboardParalax.jpg';
import paralax2 from '../../img/paralax2.jpg';
import Introduction from './Introduction';
import Bi1 from '../../img/Bi/Bi1.png'
import Bi2 from '../../img/Bi/Bi2.png'
import Bi3 from '../../img/Bi/Bi3.png'
import SectionContainer from './SectionContainer';
import BiArray from './BiArray'


const Bi = () => {
    let firstSectionP = 
        <>
            With the endless amounts of data organizations are struggling to make sense of every single day, there has never been a bigger need for intelligent business tools and technologies to transform big data into actionable, business information. VALOORES’ Business Intelligence technologies provide fast and reliable data analysis for high level, strategic and day-to-to day operations. The vast suite of VALOORES Business Intelligence technologies include reporting, analytics, data mining, business performance management, benchmarking, key performance and risk indicators, and prescriptive and predictive analytics.
            <br /><br />
            VALOORES offers Business Intelligence tools and capabilities designed to handle the challenge of structured and unstructured big data to help identify, develop, and create new strategic business opportunities and planning, and support organizations in implementing effective strategies based off insights grasped from Business Intelligence capabilities. Enterprises will be able to gain a competitive edge by utilizing VALOORES BI tools that help in identifying new market trends, changing customer needs and demographics, and maintain long-term stability and growth.
            <br /><br />
            In addition, VALOORES Business Intelligence draws from a collection of multiple data sources- operational, historical, and external- with proven data integrity in order to greatly enhance Business Intelligence technology capabilities and generate real “intelligence” that cannot be derived otherwise from any singular data set. VALOORES Business Intelligence tools are used to support a wide range of business decisions from day to day operations to strategic level planning; presenting actionable information and intelligence for executives and managers.
        </>

    let secondSectionP = 
        <>
             VALOORES Business Intelligence technologies employ advanced data mining techniques, statistical modelling, and big data analytics with prescriptive and forecasting capabilities that run through a user friendly interface. Business Intelligence tools allow users to run queries against data which create reports, dashboard and data visualizations in order to make analytical results available to corporate and operational work. Such tools include the following:
                <ul className='list-disk'>
                    <li>Reporting (KPI’s, metrics),</li>
                    <li>Automated monitoring and alerting,</li>
                    <li>Dynamic Dashboards</li>
                    <li>Data Visualizations Tools (graphs, charts, maps, scorecards)</li>
                    <li>Unlimited drill-down capabilities</li>
                    <li>Ad-hoc querying</li>
                    <li>Operational and real-time BI</li>
                </ul>
        </>

    let thirdSectionP = 
        <>
            Our user friendly, easily to navigate tools are used across a large number of executive and operational users, and across a variety of organizational domains applicable to all of VALOORES’ multi-line of business solutions. At the core, business users are able to interact with agile, intuitive systems and tools to analyze data more quickly and efficiently, gaining insight into quickly changing dynamics and providing actionable intelligence.
            <ul>
                <li>Build Business indicators</li>
                <li>Collect information from multiple data sources (files, feeds, databases, applications) in different platforms (Oracle, MySQL, Sales Force, CRM) to retrieve and calculate actionable business indicators using filtered values, formulas or aggregated data.</li>
                <li>Perform back end linking to business or structured queries to be applied to interpretation tools (charts, pivot table, tabular view, report, displayed in dashboard panel elements.)</li>
                <li>Design dashboard elements using logical or functional process
                KPI Real Time Execution</li>
                <li>Secure connection and login to the different dashboard modules</li>
                <li>Collaborate real time to perform visual analysis and reporting using adaptive web interface (browser, tablet, smart phone)</li>
                <li>Automated conditional processing and execution based on threshold values.</li>
                <li>Dynamic user interaction to filter, drill down and expose data in different angles</li>
                <li>Analytics</li>
                <li>VALOORES’ embedded Analytics engine evaluates historical trends, seasonality, cycles, elasticity, confidence intervals, outliers and other environment variables, to produce forecast, and accentuate decision making, strategies and conflicts’ resolution, based on forecast-aware BI.</li>
            </ul>
        </>
    
  return (
    <>
    <Helmet>
      <title>Business Inteligence</title>
    </Helmet>
    <Carousel />
    <Introduction />
    <SectionContainer 
        title = "It's VALOORES Business Intelligence"
        description = {firstSectionP}
        image = {Bi1}
        backgroundColor = '#f1f1f1'
        layout= ''
    />
    <SectionContainer 
        title = "Business Intelligence Tools"
        description = {secondSectionP}
        image = {Bi3}
        backgroundColor = ''
        layout= 'flex-row-reverse'
    />
    <div>
        <div style={{background: '#f1f1f1'}} className='p-3'>
                <div className='d-flex flex-column align-items-center justify-content-center text-center container'>
                    <h3 style={{color: '#001d6e'}}>The Power of in’Via</h3>
                    <p style={{lineHeight: '2rem'}}>The in’Via, our signature Business Intelligence tool, is dedicated to executives and business users to empower their everyday business decisions, via a direct access to a library of Key Performance and Risk Indicators. The in’Via includes a Dashboard Builder with Alerts and Tasks, an Application Builder, and a Library of KPRIs.</p>
                </div>
            <div className="container d-flex flex-lg-row flex-md-column flex-sm-column flex-xs-column justify-content-between gap-3 ">
                {BiArray.map((biItem, index) => (
                    <div className="card p-3 col-lg-4 col-md-12" style={{background: '#fff'}}>
                        <img src={biItem.img} className='w-100 mb-3' alt="" />
                        <h5 style={{color: '#001d6e'}}>{biItem.title}</h5>
                        {biItem.desc}
                    </div>
                ))}
            </div>
        </div>
    </div>
    <SectionContainer 
        title = "Business Intelligence in Action"
        description = {thirdSectionP}
        image = {Bi2}
        backgroundColor = ''
        layout= 'flex-row-reverse'
    />
      <Paralax img={dashboardImg} title="Powerful Dashboard Visualizations" link='/Bi' button='Learn More' laptop= 'true' height='50rem' top = '0%'/>
      <Application />
      <Paralax img={paralax2} title="You have the VALUE, we bring you the ADDED VALUE. it's VALOORES!" link='/aboutUs?variable=Contact' button='Contact Us' laptop= 'false' height='30rem' top='30%'/>
      {/* <News /> */}
  </>
);
};

export default Bi