import Client1 from '../../img/clients/abl.png';
import abl from '../../img/clients/abl.png';
// import ambank from '../../img/clients/ambank.png';
// import arabia_insurance from '../../img/clients/arabia_insurance.png';
import atak from '../../img/clients/atak.png';
import auchan from '../../img/clients/auchan.png';
// import bandq from '../../img/clients/bandq.png';
// import bankaudi from '../../img/clients/bankaudi.png';
import bdl from '../../img/clients/bdl.png';
// import bemo from '../../img/clients/bemo.png';
import bhv from '../../img/clients/bhv.png';
import bob from '../../img/clients/bob.png';
import bobfinance from '../../img/clients/bobfinance.png';
import cvs from '../../img/clients/cvs.png';
import qib from '../../img/clients/qib.png';
import kafalat from '../../img/clients/kafalat.png';
import fnac from '../../img/clients/fnac.png';
import carrefour from '../../img/clients/carrefour.png';
import canadiantyre from '../../img/clients/canadiantyre.png';
import lafayette from '../../img/clients/lafayette.png';
import target from '../../img/clients/target.png';
import fransa from '../../img/clients/fransa.png';
import blf from '../../img/clients/blf.png';
import csc from '../../img/clients/csc.png';
import bandq from '../../img/clients/bandq.png';
import fin from '../../img/clients/fin.png';
import king from '../../img/clients/king.png';
import louisdelhaize from '../../img/clients/louisdelhaize.png';
import mono from '../../img/clients/mono.png';
import nasco from '../../img/clients/nasco.png';
import oken from '../../img/clients/oken.png';
import oracle from '../../img/clients/oracle.png';
import provera from '../../img/clients/provera.png';
import refinitiv from '../../img/clients/refinitiv.png';
import thomsonreuters from '../../img/clients/thomsonreuters.png';
import simply from '../../img/clients/simply.png';
import valeo from '../../img/clients/valeo.png';

// const ClientArr =
// [
//     {
//         img: {Client1},
//         title: "AML",
//         desc: "AML client"
//     },
//     {
//         img: {Client1},
//         title: "AML",
//         desc: "AML client"
//     },
//     {
//         img: {Client1},
//         title: "AML",
//         desc: "AML client"
//     },
//     {
//         img: {Client1},
//         title: "AML",
//         desc: "AML client"
//     },
//     {
//         img: {Client1},
//         title: "AML",
//         desc: "AML client"
//     },
// ]
const ClientArr = [
    {
        image: Client1,
        title: "ABL",
    },
    {
        image: carrefour,
        title: "Carrefour",
        link: 'https://www.carrefour.com'
    },
    // {
    //     image: qib,
    //     link: 'https://www.qib.com.qa'
    // },
    {
        image: bob,
        title: "BOB",
        link: 'https://insurance.valoores.com/'
    },
    // {
    //     image: kafalat,
    //     link: 'https://kafalat.com.lb/'
    // },
    {
        image: fnac,
        title:'FNAC',
        link: 'https://fnac.com/'
    },
    {
        image: cvs,
        title:'CVS',
        link: 'https://www.cvs.com'
    },
    {
        image: bdl,
        title: 'BDL',
        link: 'https://insurance.valoores.com/'
    },
    {
        image: bobfinance,
        title: 'Bob Finance',
        link: 'https://insurance.valoores.com/'
    },
    {
        image: canadiantyre,
        title: 'Canadian Tyre',
        link: 'https://www.canadiantire.ca/en.html'
    },
    // {
    //     image: fransa,
    //     link: 'https://www.fransabank.com/'
    // },
    // {
    //     image: blf,
    //     link: 'https://www.eblf.com/'
    // },
    // {
    //     image: abl,
    //     link: 'https://banking.valoores.com/'
    // },
    // {
    //     image: ambank,
    //     link: 'https://insurance.valoores.com/'
    // },
    // {
    //     image: arabia_insurance,
    //     link: 'https://insurance.valoores.com/'
    // },
    // {
    //     image: atak,
    //     link: 'https://insurance.valoores.com/'
    // },
    {
        image: auchan,
        title: 'Auchan',
        link: 'https://banking.valoores.com/'
    },
    {
        image: bandq,
        title: 'BandQ',
        link: 'https://insurance.valoores.com/'
    },
    // {
    //     image: bankaudi,
    //     link: 'https://banking.valoores.com/'
    // },
    // {
    //     image: bemo,
    //     link: 'https://insurance.valoores.com/'
    // },
    {
        image: bhv,
        title: 'BHV',
        link: 'https://insurance.valoores.com/'
    },
    {
        image: csc,
        title: 'CSC',
        link: 'https://insurance.valoores.com/'
    },
    {
        image: target,
        title: 'Carrefour',
        link: 'https://www.carrefour.com'
    },
    {
        image: lafayette,
        title: 'Lafayette',
        link: 'https://fnac.com/'
    },
    // {
    //     image: bandq,
    //     title: 'BandQ',
    //     link: 'https://fnac.com/'
    // },
    {
        image: fin,
        title: 'Fin',
        link: 'https://fnac.com/'
    },
    {
        image: king,
        title: 'King',
        link: 'https://fnac.com/'
    },
    {
        image: louisdelhaize,
        title: 'Louis Delhaize',
        link: 'https://fnac.com/'
    },
    {
        image: mono,
        title: 'Mono',
        link: 'https://fnac.com/'
    },
    {
        image: nasco,
        title: 'Nasco',
        link: 'https://fnac.com/'
    },
    // {
    //     image: oken,
    //     link: 'https://fnac.com/'
    // },
    {
        image: oracle,
        title: 'Oracle',
        link: 'https://fnac.com/'
    },
    {
        image: provera,
        title: 'Provera',
        link: 'https://fnac.com/'
    },
    {
        image: refinitiv,
        title: 'Refinitiv',
        link: 'https://fnac.com/'
    },
    {
        image: simply,
        title: 'Simply',
        link: 'https://fnac.com/'
    },
    {
        image: thomsonreuters,
        title: 'Thomson Reuters',
        link: 'https://fnac.com/'
    },
    {
        image: valeo,
        title: 'Valeo',
        link: 'https://fnac.com/'
    },
]

export default ClientArr;