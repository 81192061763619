import React from 'react';
import { useParams } from "react-router-dom";
import JobsArray from './maps/JobDetails'; 
import Parent from './maps/JobsArray'; 
import {Link} from 'react-router-dom'
import logo from '../../ValooresNew/assets/img/full_logo.png';
import './JobDesc.css';
const JobDescriptionPage = () => {
  const { jobtitle } = useParams();
  const jobTitleDecoded = decodeURIComponent(jobtitle);

  console.log('JobsArray:', JobsArray);

  const job = Array.isArray(JobsArray) ? JobsArray.find(job => job.title.toLowerCase() === jobTitleDecoded.toLowerCase()) : null;
  const jobParent=Array.isArray(Parent) ? Parent.find(job => job.title.toLowerCase() === jobTitleDecoded.toLowerCase()) : null;

  if (!job) {
    return <p>Job not found!</p>;
  }
  return (
    <div className="job-container p-5">
      <div className='job-wrapper m-5 px-5 py-3'>
      <div className='d-flex justify-content-center flex-column align-items-center'>
        <img src={logo} alt={logo} width='400'/>
        <h2 className="title">{job.title}</h2>
      </div>

        <section className="section">
          <h3 className="section-title">Job Overview</h3>
          <p>{jobParent.desc}</p>
        </section>
        <section className="section">
          <h3 className="section-title">Requirements</h3>
          <ul className="list">
            {job.requirements.map(req => (
              <li key={req.id}>{req.detail}</li>
            ))}
          </ul>
        </section>

        <section className="section">
          <h3 className="section-title">Responsibilities</h3>
          <ul className="list">
            {job.responsibilities.map(res => (
              <li key={res.id}>{res.detail}</li>
            ))}
          </ul>
        </section>

        <section className="section">
          <h3 className="section-title">Qualifications</h3>
          <ul className="list">
            {job.qualifications.map(qual => (
              <li key={qual.id}>{qual.detail}</li>
            ))}
          </ul>
        </section>
        <div className='d-flex justify-content-end w-100 '>
          <Link to={jobParent.link} className='apply-button'>Apply</Link>
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionPage;
