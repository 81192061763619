import React from 'react'
import Sidebar from '../Banking/BankingPages/SideMenu'
import Banner from './Banner'
import Content from './Content'
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom';
import inKernel1 from '../../img/inkernel/inKernel1.jpg';
import inKernel2 from '../../img/inkernel/inKernel2.jpg';
import inKernel3 from '../../img/inkernel/inKernel3.jpg'
import inKernel4 from '../../img/inkernel/inKernel4.jpg'
import inKernel5 from '../../img/inkernel/inKernel5.jpg'
import inSystemArray from './content/inSystem';
import inWorkflowArray from './content/inWorkflow';
import inMultiArray from './content/inMultilanguage';
import inReportArray from './content/inReporting';
import inIntegrationArray from './content/inIntegration';

const Feature = () => {
    const location = useLocation();
    const currentURL = window.location.href;
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('variable');
    console.log(receivedVariable);
    let title, BannerImg, BannerDesc, RiskArray, overview, demo, pdf, fact, paper;    

    switch(receivedVariable){
        case 'inSystem':
          
            title = "in'System - Roles & Profiles"
            BannerImg = inKernel1
            BannerDesc = "“in'System solution helps you to create, modify and manage users, track their activity and configure their access rights to minimize the margin of errors, maximize productivity and save time.”"
            RiskArray = inSystemArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inWorkflow':
          
            title = "in'Workflow - Business Process Management"
            BannerImg = inKernel2
            BannerDesc = "“The global trend is to automate, optimize and secure companies' business processes, while giving retailers the opportunity to dedicate their efforts in executing unique business strategies to remain competitive in the global market.”"
            RiskArray = inWorkflowArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inMultilanguage':
          
            title = "in'Workflow - Business Process Management"
            BannerImg = inKernel3
            BannerDesc = "“in’Multilanguage, a one core solution, to translate every application and modify keys and menus tailored to every user’s need to minimize the margin of errors, maximize productivity and save time.”"
            RiskArray = inMultiArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inReporting':
          
            title = "in'Reporting"
            BannerImg = inKernel4
            BannerDesc = "“With in'Reporting powerful analytics and intuitive web interface, banks’ personnel can create insightful reports that help making data-driven decisions that ultimately have a positive impact on their bottom line.”"
            RiskArray = inReportArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inIntegration':
          
            title = "in'Integration"
            BannerImg = inKernel4
            BannerDesc = "“in’Integration cleans the multiple sources of information in order to prevent misinformation and duplicates. It's fully incorporated to the multiple data sources, there is no transfer of information and the information is 100% accurate and reliable.”"
            RiskArray = inIntegrationArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
    

        default:
        break;
        }

        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    
                    <Content RiskArray = {RiskArray}/>
                </div>

                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar currentURL={currentURL}/> 
                </div>
            </div>
        </>
    )
}

export default Feature