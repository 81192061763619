import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
// import wissam from './employee-img/wissam-semaan.png'
import valoores from '../ValooresNew/assets/img/full_logo.png';
import axios from 'axios';
const EmployeeDetails = ({Ip}) => {
  const [employeeData,setEmployeeData]=useState([]);
  const { employeeName } = useParams();
  const decodedName = decodeURIComponent(employeeName);

  const fetchEmployeeData = async() =>{
    try{
        const formData=new FormData();
        formData.append('name', employeeName);
        formData.append('Action', 'name');
        const response = await axios.post(`${Ip}controllers/employees.php`, formData);
        if(response.data.success){
            setEmployeeData(response.data.data);
            console.log(response.data);
        }
        else{
            console.error(response.data.error);
        }
    }
    catch(error){
        console.error(error);
    }
  }

  useEffect(()=>{
    fetchEmployeeData();
  },[]);

  const employee = employeeData.find(
    (emp) => emp.name.toLowerCase() === decodedName.toLowerCase()
  );

  if (!employee) {
    return <div>Employee not found</div>;
  }

  return (
    <>
      {
        (() => {
          const imagePath = `${Ip}${employee.img}`; 
          
          return (
            <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
              <div style={{
                width: 'fit-content',
                padding: "20px",
                border: "1px solid #000",
                borderRadius: "8px",
                marginTop: '5rem',
                marginBottom: '2rem'
              }}>
                <div className="mb-2 text-center">
                  <img  src={valoores} 
                        alt={employee.name}
                        style={{ width: "200px", height: "50px"}}/>
                </div>  
                <h4>{employee.name}</h4>
                <div style={{ display: "flex", gap: "20px", margin: "8px" }}>
                  {/* <div style={{ display: "flex", justifyContent: "center",alignItems:'center' }}> */}
                    <img
                      src={valoores}
                      alt={employee.name}
                      style={{ width: "250px", height: "100px", borderRadius: "20%" }}
                    />
                  {/* </div> */}
                  <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "120px 1fr" }}>
                    <div><strong>Department:</strong></div>
                    <div>{employee.department}</div>
                    <div><strong>Position:</strong></div>
                    <div>{employee.position}</div>
                    <div><strong>Extension:</strong></div>
                    <div>{employee.ext}</div>
                    <div><strong>Phone:</strong></div>
                    <div>{employee.phone_num}</div>
                    {/* <div><strong>Plate Number:</strong></div>
                    <div>{employee.plate}</div>
                    <div><strong>Car Model:</strong></div>
                    <div>{employee.model}</div> */}
                    <div><strong>Location:</strong></div>
                    <div>{employee.location}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })()
      }
    </>
  );  
};

export default EmployeeDetails;
