import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../img/request-demo-banner.jpg"
// import "./styles.scss"; // Assuming you have a CSS file for custom styling

const TermsAndConditions = () => {
  return (
    <div>
      <header>
        <img
          src={img}
          className="img-fluid w-100"
          alt="ibs Category Management"
        />
      </header>

      <main className="container">
        <h2 className="mt-4">Terms & Conditions</h2>

        <h3>General Notice</h3>
        <p>
          This website falls under the control of VALOORES. The following
          conditions apply to anyone accessing and using this website or any
          part of it. VALOORES may modify from time to time these conditions,
          and you are advised to review them regularly. By using this website,
          you agree to be bound by these conditions.
        </p>

        <div className="row">
          <div className="col-md-6">
            <ul>
              <li>
                VALOORES provides information using its best endeavors to
                achieve accuracy. All this information is supplied "as is,"
                without warranty of any kind.
              </li>
              <li>
                VALOORES takes reasonable measures to ensure data quality. In
                no case whatsoever can VALOORES be held liable for the content,
                use, or inability to use this website.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul>
              <li>
                VALOORES excludes liability for third-party information made
                available on the website.
              </li>
              <li>
                VALOORES offers links to other sites but does not control their
                content and holds no liability regarding them.
              </li>
            </ul>
          </div>
        </div>

        <h3>Copyright Notice</h3>
        <p>
          Any copy, adjustment, modification, or other form of exploitation of
          this website is strictly prohibited unless with prior written
          approval from VALOORES.
        </p>

        <h3>Trademark and Trade Names</h3>
        <p>
          This service, including text, graphics, logos, and images, is owned
          exclusively by VALOORES. Unauthorized use is prohibited.
        </p>
      </main>
    </div>
  );
};

export default TermsAndConditions;