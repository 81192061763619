import React from "react";
import img from "../img/request-demo-banner.jpg";

const PrivacyPolicy = () => {
  return (
    <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6", color: "#333", margin: "0 auto"}}>
      <section style={{ textAlign: "center", marginBottom: "20px" }}>
        <img
          src={img}
          style={{ width: "100%", borderRadius: "8px" }}
          alt="ibs Category Management"
        />
      </section>
      <article className="container">
        <h2 style={{ borderBottom: "2px solid #001f3d", paddingBottom: "5px" }}>Privacy Policy</h2>
        <h3 style={{ color: "#001f3d" }}>Site Terms</h3>
        <p>
          By accessing this website you agree to be bound by these Terms & Conditions ("terms"), please read them carefully. If you do not agree to be bound by these terms, you should not access or view this website.
        </p>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          <div style={{ flex: "1 1 100%", minWidth: "300px" }}>
            <ul style={{ paddingLeft: "20px" }}>
              <li>The information contained in this website is intended for general information purposes only. VALOORES has made all reasonable efforts to ensure that the information on this website is accurate at the time of inclusion, however there may be inaccuracies and occasional errors for which VALOORES apologizes.</li>
              <li>VALOORES makes no representations or warranties about the information provided through this website, including any hypertext links to any website or other items used either directly or indirectly from this website.</li>
            {/* </ul>
          </div>
          <div style={{ flex: "1 1 100%", minWidth: "300px" }}>
            <ul style={{ paddingLeft: "20px" }}> */}
              <li>You hereby agree to indemnify VALOORES against any costs, claims, losses, and damages (including legal fees) incurred by or awarded against VALOORES as a result of your misuse of this website.</li>
              <li>These terms may be amended by VALOORES from time to time.</li>
            </ul>
          </div>
        </div>
        <h3 style={{ color: "#001f3d" }}>Privacy Policy</h3>
        <p>
          VALOORES respects the privacy of visitors and users of this website. Any questions or inquiries should be addressed to:
          <br />
          <a href="mailto:HR@valoores.com" style={{ color: "#001f3d", textDecoration: "none" }}>HR@valoores.com</a>
          <br />
          700 Feghali Center, Abou Jaoudeh Street, Bouchrieh, Beirut, Lebanon
          <br />
          Tel: +961 1 90 11 70
          <br />
          Fax: +961 1 90 11 75
          <br />
          Web: <a href="http://www.valoores.com" style={{ color: "#001f3d", textDecoration: "none" }}>www.valoores.com</a>
        </p>
      </article>
    </div>
  );
};

export default PrivacyPolicy;