
const InDataGovernance = [
    {
      title: "Overview",
      desc:
      (
        <>
          <br />
          With the popularity of implementing business intelligence (BI) applications, it is vital for Banks and Financial Institutions to manage the Master Data Management (MDM) in order to offer a consistent and centralized view of the data across the enterprise’s systems.
          <br />
          The business data is in a continuous leveraging, which may lead to duplications, incomplete or inaccurate data. VALOORES MDM solution is designed to centralize, cleanse, govern and share the data across the organization's systems, and to synchronize it downstream the different applications and platforms.
          <br />
          The banking industry has a challenge in optimizing the relationships with the customers, to create cross-sell opportunities and to reduce the agility costs. Therefore the banking sector has started powering the MDM to accomplish better flexibility and to reduce data costs, especially the data maintenance costs. When the business starts to suffer from operational and efficiency challenges, the need for data consistency, accuracy and uniqueness becomes crucial.
          <br />
        </>
      ),
      list: [''],
    },
    {
        title: "VALOORES embraces the “SECOND WAVE” of FinTech, RegTech, & GovTech Trends",
        desc: 'VALOORES embraces the “Second Wave” by focusing on the trends with highest potential within such a vibrant ecosystem.',
        list: [
         "Reliable base of information adaptable for different business activities.",
         "Data consistency across the business flow management.",
         "Data values standardization managed by the industry standards and metrics.",
          
        ],
      },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
         "Reliable base of information adaptable for different business activities.",
         "Data consistency across the business flow management.",
         "Data values standardization managed by the industry standards and metrics.",
          
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
            "Industry leading Transfer Pricing Methods support base transfer rates",
            "TP methods available for ALM Forecasting and Budgeting",
            "Option, Liquidity, and Breakage Charges",
            "Visibility into the components of net interest margin",
            "Shared data model and platform",
            "Enterprise class scalability",
            "Integration of Risk, Performance Management, Customer Insight, and Financial Compliance",
            
        ],
      },
      {
        title: "",
        desc:'We put forward a list of pre-built MDM objects that will be used by the bank’s different systems and platforms as shared cleansed information structure. Below is a sample on some of the main structures dealing with the MDM principal conceptual entities:',
        list: 
        [
          "Party Main Information: identifying the attributes related to an individual or firms that exchanges information and services within the bank.",
          "Person Main Information: identifying the attributes related to an individual of interest to the bank.",
          "Company Main Information: identifying the attributes related to a separate legal entity which conducts operations for the bank in some geographical location. It is considered as a separate legal entity.",
          "Customer Main Information: identifying the attributes related to a person or individuals, who purchase, may purchase or have purchased services from the bank. The customer data model includes the management of the different segmentation supporting the customer behavior; this allows the bank to create as possible segmentation and customer grouping as the business needs.",
          "Location Main Information: identifying the attributes related to a physical place the bank conducts its business. It may be any or all of the defined sub-types: Work Location, Selling Location and Inventory Location. This entity groups the link with the address parameterization and the contacts dealing with the locations; knowing that the design of the location functions allows dividing not only the bank to branches and head offices but also to dividing the same branch into different locations such as floors, departments, etc.",
          "Dynamic Status Management: providing the bank/financial institution to parameterize the different statuses corresponding to the different business objects and processes available. Several other options are presented; with an essential facility lying in the fact that conditions can be set to allow the revolution from one status to a consecutive one.",
          "Account Main Information: identifying the attributes managed at the account definition level such as account number, related branch, related customer, ledger information, interest information, commission information…",
          "Product Main Information: identifying the attributes related to the lowest level of the product hierarchy retained within the bank. The same data model could be used for any types of products and services managed within the bank; where we centralize the creation of the cards, loans, insurance and other services in a centralized place differing by type.",
          "Setup Parameters: defined system parameters that rely on ISO Codification Lists and even other international standard lists such as Country, Country Currency, Country Divisions, Currency, Language, Measure Unit, Time Zone, etc.",
        ],
    }
  ];
  
  export default InDataGovernance;
  