import React from 'react';
import Carousel from './Carousel';
import Introduction from './Introduction';
import Sections from './Sections';
import Paralax from './Paralax';
import Application from './Application';
import InAnalyticsArray from './AnalyticsPages/content/InAnalytics';
import InVia from './AnalyticsPages/content/InVia';
import Fade from 'react-reveal/Fade'
import dashboardImg from '../../img/dashboardParalax.jpg'
import paralax2 from '../../img/paralax2.jpg'
import CardSection from './CardSection'
import Publications from './Publications'
import { Helmet } from 'react-helmet';


const Analytics = () => {
  
 
  return (
    <>
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <Carousel/>
      <Fade bottom>
        <Introduction />
      </Fade>
        <Sections />
        <CardSection CardArray = {InAnalyticsArray}/>
        <Publications />
        <Paralax img={dashboardImg} title="Powerful Dashboard Visualizations" link='/banking' button='Learn More' laptop= 'true' height='50rem' top = '0%'/>
        <Application />
        <Paralax img={paralax2} title="You have the VALUE, we bring you the ADDED VALUE. it's VALOORES!" link='/aboutUs?variable=Contact' button='Contact Us' laptop= 'false' height='30rem' top='30%'/>
        {/* <News /> */}
    </>
  );
};

export default Analytics;
