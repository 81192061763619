import App1 from '../../img/app1.png';
import App2 from '../../img/app2.png';
import App3 from '../../img/app3.png';

const applicationSection = [
  {
    icon: App1,
    title: "in'AML",
    desc: "in'AML solution provides an enterprise platform enabling efficient detection, investigation, and reporting of suspected money laundering and terrorist financing activity. It helps financial institutions tackle risk & compliance problems.",
    video: 'BQc6a9XEvKc',
    more: '/banking/riskandcompliance?variable=Aml',
    quick: 'https://demo.valoores.com/valooresdashboards/',
    demo: '/banking/requestdemo',   
  },
  {
    icon: App2,
    title: "in'Profit",
    desc: "in'Profit solution drives a company's profitability. Rich, intelligent & compliant to international standards are in'Profit's bread and butter. Instant decision making tool with wide range of analytics.",
    video: 'mdfYKS5bRio',
    more: '/banking/inprofit',
    quick: 'https://demo.valoores.com/valooresdashboards/',
    demo: '/banking/requestdemo',
  },
  {
    icon: App3,
    title: "in'IFRS9",
    desc: "in'IFRS9 solution is built on a logical, single classification and measurement approach for financial assets. It will accommodate you to cope with the new tsunami of Compliance, Regulations and Risk Management in 2018.",
    video: '-8cjAawAoac',
    more: '/banking/ifrs9',
    quick: 'https://demo.valoores.com/valooresdashboards/',
    demo: '/banking/requestdemo',
  },
];


export default applicationSection;