import React from 'react'
import Sidebar from '../Banking/BankingPages/SideMenu'
import Banner from './Banner'
import Content from './Content'
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom';
import InAsset1 from '../../img/inasset/InAsset1.jpg';
import InAsset2 from '../../img/inasset/InAsset2.jpg';
import inRealEstateArray from './content/inAsset';
import inCapitalArray from './content/inCapital';

const Feature = () => {
    const location = useLocation();
    const currentURL = window.location.href;
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('variable');
    console.log(receivedVariable);
    let title, BannerImg, BannerDesc, RiskArray, overview, demo, pdf, fact, paper;    

    switch(receivedVariable){
        case 'inRealEstate':
          
            title = "in'Real Estate & Financial Assets"
            BannerImg = InAsset1
            BannerDesc = "“in’Real Estate & Financial Assets solution is helping medium to large enterprises managing and maintaining their huge real estate & financial assets data in an easy and agile organization while preserving at the same time a high security level.”"
            RiskArray = inRealEstateArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inCapital':
          
            title = "in'Capital Market"
            BannerImg = InAsset2
            BannerDesc = "“in'Capital Market offers solutions in middle-office and back-office functions enabling key capital market players like asset managers, custodians, broker/dealers, fund administrators to retain competitive advantage in a dynamic market environment and adapt to challenges.”"
            RiskArray = inCapitalArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

    
    

        default:
        break;
        }

        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    
                    <Content RiskArray = {RiskArray}/>
                </div>

                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar currentURL={currentURL}/> 
                </div>
            </div>
        </>
    )
}

export default Feature