import React from 'react';
import {Link} from 'react-router-dom'
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '350',
    width: '100%',
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      mute: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
  
  const Introduction = () => {
   
  // const videoId = 'lRoRZGMc4cs';
  const videoId = '71NGqxCw4Po';
  return (
    <>
      <div className="container mt-5 intro_container">
        <div className="row align-items-center">
            <div className="col-md-6">
              <div className="video_player-container">
                <VideoPlayer videoId={videoId}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content__title">
                <h2>
                  <span>About Us</span>
                </h2>
                <p>
                  VALOORES is one of the global leading providers of Enterprise Management & Decision Making solutions. Established in 1989, VALOORES operates in ten countries. We are a world class company providing products and services to global Mega Tier 1 enterprises.
                </p>
                {/* <div className="button_div">
                    <Link className="btn btn-show-sec" to='https://demo.valoores.com/valooresdashboards/' target= '_blank'>Request A Demo</Link>
                </div> */}
                {/* <div className="button_div"> */}
                    <Link className="button_read_more" to='/aboutUs?variable=Contact' target= '_blank'>Read More</Link>
                {/* </div> */}
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Introduction