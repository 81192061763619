import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {AiOutlineSearch}  from 'react-icons/ai'
import JobsArray from './maps/JobsArray'
import './Jobss.css';


const Jobs = ({ activeJobCat, targetSectionRef }) => {
    const [filteredJobs, setFilteredJobs] = useState([]);

    useEffect(() => {
        const filtered = JobsArray.filter(job => job.category === activeJobCat);
        setFilteredJobs(filtered);
    }, [activeJobCat]);

    const handleJobClick = title => {
        console.log('Clicked on job:', title);
    };

    useEffect(() => {
        if (window.location.hash === '#jobs_scroll') {
          targetSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }, []);

    return (
        <>
            <div ref={targetSectionRef} className='container' id='jobs_scroll'>
                <div className='input-group align-items-center'>
                    {/* <input type="text" placeholder='Search' className='w-100 form-control search_bar' style={{ position: 'relative' }} /> */}
                    {/* <AiOutlineSearch style={{ position: 'absolute', zIndex: '5', right: '1%', fontSize: '1.5rem' }} /> */}
                </div>
                <div className="job_contents my-5">
                    {filteredJobs.length > 0 ? (
                        filteredJobs.map((job, index) => (
                            // <Link to={job.linkto} target='_blank' style={{color: '#000', textDecoration: 'none'}}>
                            <div style={{color: '#000', textDecoration: 'none'}}>
                                {/* <Link to={`/hr/career/${job.title}`} style={{color: '#000', textDecoration: 'none'}}> */}
                                <div className="mb-3" key={index} >
                                    <div className='job_content' style={{width: '85vw'}}>
                                        <h6>{job.category}</h6>
                                        <h3 className='w-80'>{job.title}</h3>
                                        <h6 className='w-80' style={{ lineHeight: '1.5rem' }} dangerouslySetInnerHTML={{__html:job.desc}}></h6>
                                        <div className='d-flex flex-row justify-content-between w-100'>
                                            <p>{job.location}</p>
                                            <p>{job.level}</p>
                                        </div>
                                        <div className='d-flex flex-row justify-content-between w-100'>
                                        <Link to={job.linkto} target="_blank" rel="noopener noreferrer" className='job-description'>Job Description</Link>
                                        <Link to={job.link} target="_blank" className='apply-now'>Apply Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="mb-3">
                            <p>No Jobs Available At The Moment</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Jobs;